var generic = generic || {};
var site = site || {};

generic.endeca = generic.endeca || {
    catalog: {},
    result: {},
    results: {},
    resultsgroup: {},
    mixins: {},
    instances: {},
    generic: {
        Class: generic.Class || {},
        env: generic.env || {},
        rb: generic.rb || {},
        template: generic.template || {}
    },
    helpers: {
        array: {
            toInt: function( array ) {
                for ( var i = 0; i < array.length; i++ ) {
                    array[i] = parseInt( array[i] );
                }
                return array;
            },
            unique: function( array ) {
                var o = {}, a = [];
                for ( var i = 0; i < array.length; i++ ) {
                    if ( typeof o[array[i]] == 'undefined' ) { a.push( array[i] ); }
                    o[array[i]] = 1;
                }
                return a;
            },
            remove: function( array, valuesToRemove ) {
                var newArray;
                var valuesToRemove = jQuery.isArray( valuesToRemove ) ? valuesToRemove : [valuesToRemove];
                return jQuery.grep( array, function( value ) {
                    return jQuery.inArray( value, valuesToRemove ) == -1 ;
                });
            }
        }, 
        func: {
            bind: function() { 
                var _func = arguments[0] || null; 
                var _obj = arguments[1] || this; 
                var _args = jQuery.grep(arguments, function(v, i) { 
                    return i > 1; 
                }); 
            
                return function() { 
                    return _func.apply(_obj, _args); 
                }; 
            }
        },
        string: {
            toQueryParams: function( string, separator ) {
            	var string = string || '';
            	var separator = separator || '&';
            	var paramsList = string.substring(string.indexOf('?')+1).split('#')[0].split(separator || '&'), params = {}, i, key, value, pair;
            	for (i=0; i<paramsList.length; i++) {
            		pair = paramsList[i].split('=');
            		key = decodeURIComponent(pair[0]);
            		value = (pair[1])?decodeURIComponent(pair[1]):'';
            		if (params[key]) {
            			if (typeof params[key] == "string") { params[key] = [params[key]]; }
            			params[key].push(value);
            		} else { params[key] = value; }
            	}
            	return params;
            }  
        },
        obj: {
            first: function( obj ) {
                for ( var i in obj ) { return obj[i]; }
            },
            slice: function( obj, array ) {
                var h = {};
                for ( var i = 0; i < array.length; i++ ) {
                    if ( typeof obj[array[i]] != 'undefined' ) {
                        h[array[i]] = obj[array[i]];
                    }
                }
                return h;
            }
        }
    }
};

site.endeca = generic.endeca;

var rb = rb || {};

/**
* This method provides access to resource bundle values that have been 
* written to the HTML in JSON format. The file that outputs these values
* must be included in the .html as a script tag with the desired RB name
* as a query string paramter.
* 
* @class ResourceBundle
* @namespace generic.rb
* 
* @memberOf generic
* @methodOf generic
* @requires generic.Hash (minimal functional replication of Prototype Hash Class)
* 
* @example Inline data
* 
*    <script src="/js/shared/v2/internal/resource.tmpl?rb=account"></script>
* 
* @example Script retrival of data values
* 
*    var myBundle = generic.rb("account");
*    myBundle.get("err_please_sign_in");
*    
* 
* @param {String} rbGroupName name of resource bundle needed
* 
* @returns An object that provides the main get method
* 
*/
generic.endeca.generic.rb = function (rbGroupName) {

  var findResourceBundle = function (groupName) {

    if (groupName && rb) {

      var rbName = groupName;
      var rbObj = rb[rbName];
      if (rbObj) {
        return rbObj;
      } else {
        return {};
      }
    } else {
      return {};
    }

  };

  var resourceBundle = findResourceBundle(rbGroupName);

  var returnObj = {
    /**
    * @public This method will return the value for the requested Resource Bundle key.
    * If the key is not found, the key name will be returned.
    * 
    * @param {String} keyName key of desired Resource Bundle value
    */
    get: function (keyName) {
      if (typeof (keyName) != "string") {
        return null;
      }
      var val = resourceBundle[keyName];
      if (val) {
        return val;
      } else {
        return keyName;
      }
    }
  };

  return returnObj;

};


/**
 * Minimal Native Version of Prototype Class
 * 
 * @deprecated Jquery extend method has options for deep copy extensions
 * 
 * @class Class
 * @namespace generic.Class
 * 
 */

generic.endeca.generic.Class = { // Uppercase 'Class', avoid IE errors

  fn: function (src, props) {

    var tgt, prxy, z, fnTest = /xyz/.test(function () { xyz; }) ? /\b_super\b/ : /.*/;

    tgt = function () { // New Constructor
      // Initialize Method is a Requirement of Class
      // With the inclusion of the _super method, initialize in the superclass should only be called on demand
      /*if(tgt.superclass&&tgt.superclass.hasOwnProperty("initialize")){
          tgt.superclass.initialize.apply(this,arguments);
      }*/
      if (tgt.prototype.initialize) {
        tgt.prototype.initialize.apply(this, arguments);
      }
    };

    // Preserve Classical Inheritance using Proxy Middle
    src = src || Object;
    prxy = function () { }; /* Potentially define "Class" here */
    prxy.prototype = src.prototype;
    tgt.prototype = new prxy();
    tgt.superclass = src.prototype;
    tgt.prototype.constructor = tgt;

    // give new class 'own' copies of props and add _super method to call superclass' corresponding method
    for (z in props) {
      if (typeof props[z] == "function" && typeof tgt.superclass[z] == "function" && fnTest.test(props[z])) {
        tgt.prototype[z] = (function (z, fn) {
          return function () {
            this._super = tgt.superclass[z];
            var ret = fn.apply(this, arguments);
            return ret;
          };
        })(z, props[z])
      } else {
        tgt.prototype[z] = props[z];
      }
      /*if(props.hasOwnProperty(z)){tgt.prototype[z]=props[z];}*/
    }

    return tgt;

  },
  create: function () {

    var len = arguments.length, args = Array.prototype.slice.call(arguments), fn = generic.endeca.generic.Class.fn;

    if (len == 2) { tgt = generic.endeca.generic.Class.fn(args[0], args[1]); }
    else if (len == 1) { tgt = generic.endeca.generic.Class.fn(null, args[0]); }
    else { tgt = function () { }; /* return empty constructor */ }

    return tgt; // return constructor that stacks named Class w/ object-literal, works with instanceof

  }, // End Create Method    
  mixin: function (baseClass, mixin) {
    var newClass = baseClass;
    if (mixin && mixin.length) {
      for (var i = 0; i < mixin.length; i++) {
        newClass = generic.endeca.generic.Class.mixin(newClass, mixin[i]);
      }
    } else {
      if (mixin) { newClass = generic.endeca.generic.Class.create(newClass, mixin); }
    }
    return newClass;
  }
};


generic.endeca.generic.env = {
  isIE: !!(typeof (ActiveXObject) == 'function'),
  isIE6: !!(!!(typeof (ActiveXObject) == 'function') && (/MSIE\s6\.0/.test(navigator.appVersion))),
  isFF: !!(typeof (navigator.product) != 'undefined' && navigator.product == 'Gecko' && !((document.childNodes) && (!navigator.taintEnabled)) && /firefox/.test(navigator.userAgent.toLowerCase())),
  isFF2: !!(typeof (navigator.product) != 'undefined' && navigator.product == 'Gecko' && !((document.childNodes) && (!navigator.taintEnabled)) && navigator.userAgent.toLowerCase().split(' firefox/')[1] && navigator.userAgent.toLowerCase().split(' firefox/')[1].split('.')[0] == '2'),
  isFF3: !!(typeof (navigator.product) != 'undefined' && navigator.product == 'Gecko' && !((document.childNodes) && (!navigator.taintEnabled)) && navigator.userAgent.toLowerCase().split(' firefox/')[1] && navigator.userAgent.toLowerCase().split(' firefox/')[1].split('.')[0] == '3'),
  isMac: !!(/macppc|macintel/.test(navigator.platform.toLowerCase())),
  isSafari: !!(/Safari/.test(navigator.userAgent)),

  domain: window.location.protocol + "//" + window.location.hostname,

  parsedQuery: function () {

    var query = window.location.search.toString().split('?')[1] || '';
    var main = function () {
      var params = {};
      var pairs = query.split('&');

      if (!query) {
        return;
      }

      if (query.indexOf('?') > -1) {
        query = query.split('?')[1];
      }
      pairs.forEach(function (pair) {
        var tempPair = pair.split('=');

        params[tempPair[0]] = decodeURIComponent(tempPair[1] || '');
      });

      return params;
    };
    var parameters = main() || {};

    return parameters;
  },
  query: function (key) {
    var result = generic.endeca.generic.env.parsedQuery()[key] || null;

    return result;
  }
};


/**
 * Template.js
 * 
 * @memberOf generic
 * 
 * @class TemplateSingleton
 * @namespace generic.template
 * 
 * @requires object literal with parameters
 * 
 * @param path attribute as a literal key is required
 * @example "/templates/cart-overlay.tmpl",
 * 
 * @param {string} templateString takes first priority
 * @example templateString:'#{product.url} some-page-markup-with-#{product.url}'
 * 
 * @param {boolean} forceReload
 * 
 * @param {function} callback
 * @example
 * 
 * callback:function(html) {
 *    // Front-End Resolution
 *    jQuery('#container').html(html);
 * }
 * 
 * @param {object} query object hash with object-literals, array-literals that can be nested
 * @example example structure
 * query: {
 *    a:'',b:{},c:[],d:{[]} // keys rooted to named parent if object or array-objects are nested
 * }
 * 
 * @param {object} Hash of string-literals with string values that map to the template
 * @example
 * 
 * object: {
 *    'product.PROD_RGN_NAME':'replacement',
 *    SOME_VAR:'replacement'
 * }
 * 
 * @example Usage
 * 
 * generic.template.get({
 *    path:"/some/path/to/template.tmpl",
 *    ...
 * });
 * 
 * @param {HTML} (optional) Markup based inline template
 * @required The path attribute must match the path key passed to the get method.
 * 
 * @example Inline Template Example
 * 
 * <!-- -------------------------- Inline Template ------------------------------ -->
 * 
 * <script type="text/html" class="inline-template" path="templates/foo.tmpl">"
 *         <div>#{FIRST_NAME}</div>
 *         <div>#{SECOND_NAME}</div>
 * </script>
 * 
 * Inline Templates : Valid inline template via script tag in this format, aside
 * from the templateString parameter, will be the first candidate for the template,
 * then the cache, then ajax.
 * 
 * 
 * @returns {object} An object that refers to a singleton which provides
 * the primary get api method.
 * 
 */

generic.endeca.generic.template = (function () {

  var that = {};
  var templateClassName = ".inline-template";
  var templates = {};

  // mustache stuff
  var translations;
  var partials;
  // end mustache stuff

  /**
   * This method loads a pre-interpolation template into the object's internal cache. This cache is checked before attempting to pull the template from the DOM or load it via Ajax.
   * @param (String) key The name that is used to retrieve the template from the internal cache. Typically mathces the path for Ajax-loaded templates.
   * @param (String) html The non-interpoltaed content of the template.
   * @returns (Strin) the HTML that was originally passed in
   * @private
   */
  var setInternalTemplate = function (key, html) {
    templates[key] = html;
    return html;
  };

  var getInternalTemplate = function (key) {
    var template = templates[key];

    if (!template && site.templates && site.templates[key]) {
      templates[key] = site.templates[key].content;
      template = templates[key];
    }

    return template;
  };

  var returnTemplate = function (args) {
    var html = args.template;

    html = interpolate({ template: html, recurseParams: { object: args.object, rb: args.rb }, Lre: /\[jsInclude\]/i, Rre: /\[\/jsInclude\]/i });

    if (typeof args.rb === "object") { html = interpolate({ template: html, obj: args.rb, Lre: /\[rb\]/, Rre: /\[\/rb\]/ }); }

    //if ( typeof args.object === "object" ) { html = interpolate({ template: html, obj: args.object }); }

    if (typeof args.object === "object") {
      try {
        if (html.match(/\{\{.*\[/) && html.match(/\].*\}\}/)) {
          throw "generic.template: template expects array notation, defaulting to non-mustache rendering";
        }

        translations = translations || {
          globals: {
            t: site.translations || {},
            variables: Drupal.settings.common || {}
          }
        };

        var obj = $.extend({}, args.object, translations);

        html = Mustache.render(html, obj, templates);
      } catch (e) {
        console.log(e);
        html = interpolate({ template: html, obj: args.object });
      }
    }

    return html;

  };

  var interpolate = function (args) {
    var args = args || {};

    args.Lre = args.Lre || /\{\{/;
    args.Rre = args.Rre || /\}\}/;

    var obj = args.obj || args.rb || {};
    var tmpl = args.template || "",
      recurseParams = args.recurseParams || null,
      Lre = new RegExp(args.Lre),
      Rre = new RegExp(args.Rre),
      tmplA = [],
      temp, lft, rght;

    tmplA = tmpl.replace(/[\r\t\n]/g, " ").split(Lre); // array of (.+?)} with '}' marking key vs rest of doc

    var returnString = "";
    for (var x = 0; x < tmplA.length; x++) {
      var chunk = tmplA[x];
      var splitChunk = chunk.split(Rre);

      if (typeof splitChunk[1] !== "undefined") { // close tag is found
        var valueToInsert = "";

        if (recurseParams) {
          recurseParams['path'] = splitChunk[0];
          valueToInsert = that.get(recurseParams);
        } else {

          // First check array notation for property names with spaces
          // Then check object notation for deep references
          valueToInsert = obj['" + splitChunk[0] + "'] || obj[splitChunk[0]];
          if (typeof valueToInsert === "undefined" || valueToInsert === null) {
            valueToInsert = '';
          }
        }

        chunk = valueToInsert.toString() + splitChunk[1];
      }
      returnString += chunk;
    }
    return returnString;
  };

  that.get = function (args) {
    var key = args.path;
    var callback = args.callback;
    var forceReload = !!args.forceReload;
    var objectParam = args.object;
    var rbParam = args.rb;
    var template = getInternalTemplate(key);

    var html;

    if (template && !forceReload) {  // internal template found and OK to use cache
      html = returnTemplate({
        template: template,
        object: objectParam,
        rb: rbParam,
        callback: args.callback
      })
    } else {  // no internal template found or not OK to use cache
      // attempt to retrieve from DOM
      var matchingTemplateNode = null;
      jQuery(templateClassName).each(function () {
        if (jQuery(this).html() && (jQuery(this).attr("path") == key)) {
          matchingTemplateNode = this;
        }
      });
      if (matchingTemplateNode) { // inline template found in DOM
        template = setInternalTemplate(key, jQuery(matchingTemplateNode).html());
        html = returnTemplate({
          template: template,
          object: args.object,
          rb: rbParam,
          callback: args.callback
        });
      }
    }

    if (typeof args.callback === "function") { args.callback(html); }
    else { return html; }

  };

  that.loadMustacheMappings = function (args) {
    var args = args || { mappings: {} };

    if (args.mappings) {
      for (var key in args.mappings) {
        if (args.mappings.hasOwnProperty(key) && site.templates[args.mappings[key]]) {
          // These need to be mapped in both direction to handles partials
          templates[key] = site.templates[args.mappings[key]].content;
          templates[args.mappings[key]] = site.templates[args.mappings[key]].content;
        }
      }
    }
  }

  return that;

})();

generic.endeca.catalog = site.endeca.generic.Class.create({
	initialize: function( args ) {
        this.jsonResult = null;
        this.resultList = [];
        jQuery.extend( this, args || {} );
        
        if ( this.jsonResult ) { this.parseData(); }
	},
	
    parseData: function() {
        if ( this.jsonResult.AggrRecords ) {
            for ( var i = 0; i < this.jsonResult.AggrRecords.length; i++) {
                for ( var j = 0; j < this.jsonResult.AggrRecords[i].Records.length; j++ ) {
                    this._parseRecord( this.jsonResult.AggrRecords[i].Records[j] );
                }
            }
        } else if ( this.jsonResult.Records ) {
            for ( var i = 0; i < this.jsonResult.Records.length; i++ ) {
                this._parseRecord( this.jsonResult.Records[i] );
            }
        }
	}
});

site.endeca.catalog = generic.endeca.catalog;
generic.endeca.catalog.content = site.endeca.generic.Class.create( site.endeca.catalog, {	
	_parseRecord: function( record ) {
	    this.resultList.push({
	        "Properties": {
    	        "image": site.endeca.generic.rb('endeca').get('content.image_url'),
    	        "title": record.Properties.p_PROD_RGN_NAME,
    		    "description": record.Properties.p_DESCRIPTION,
    		    "link": record.Properties.p_url,
    		    "link_text": site.endeca.generic.rb('endeca').get('content.link_text'),
    		    "Zone": 'crawlData'
    		}
        });
	}
});


site.endeca.catalog.content = generic.endeca.catalog.content;

//
// EndecaCatalog class.
// This class parses the Endeca JSON data into category, product, and sku objects,
// and maintains lists (hashes) for each type.  You can then access each list by id,
// such as:
//		var catObj = this.categoryList[catid];
// The structure of the lists/objects is essentially the same as the "CatProdData" class
// of the CL-US project.  Thus, each product object also contains a "skus" list which
// is a list of references to sku objects (also on the skuList) which are skus for that
// product.  Thus, when you get a product object from the productList, that product
// object will have a list of all it's skus (at least the skus that were included
// in the data).
//
// There is a "rec_type" property on each data record that indicates either "product"
// or "content".  "product" record types have cat/prod/sku data, and are stored as such.
// "content" records are not products, and the data for these records is stored
// essentially verbatim on the "contentList" hash.  Typically content records are
// things like articles and videos on the site.
//
// NOTE - this class is intended only to be a convenient container for the data
// returned from an Endeca query.  Please do not add page/state data to this class.
//
// A discussion of the Endeca data format is at the end of this file.
//	

generic.endeca.catalog.product = site.endeca.generic.Class.create( site.endeca.catalog, {

	initialize: function(args) {
		
		this.categoryList = {};
		this.productList = {};
		this.skuList = {};
		
		this.parseOrderHi = 0;
		this.parseOrderLo = 0;
		
		this._super(args);
		
		this.resultList = this.getProducts();
	},
	
	_parseRecord: function( record ) {

    var propValue;
    var isPropValue = propValue === true;

    if ( record.Properties["rec_type"] === 'product' ) {
      var props = { 'c': {}, 'p': {}, 's': {} };

      props['p']['matched'] = 1;
      props['s']['matched'] = 0;

      for ( var prop in record.Properties ) {
        propValue = record.Properties[prop];

        if ( propValue && propValue !== "" && !isNaN( propValue ) ) {
          if ( propValue.match(/\./) ) {
            propValue = parseFloat(propValue);
          } else {
            propValue = parseInt(propValue);
          }
        }

        if ( prop.match(/_json/i) ) {
          prop = prop.replace(/_json/i, '');
          if (isPropValue) {
            propValue = JSON.parse(propValue);
          }
        }

        if ( prop.match(/^([a-z])_/) ) {
          props[RegExp.$1] = props[RegExp.$1] ? props[RegExp.$1] : [];
          props[RegExp.$1][prop.substr(2)] = propValue;
        }

        if ( prop === "DGraph.WhyDidItMatch" ) {
          props['s']['matchedOn'] = propValue;

          var matchedOnString = typeof propValue === "object" ? propValue.join() : propValue;

          if ( matchedOnString.match(/s_/) ) {
            props['p']['matched'] = 0;
            props['s']['matched'] = 1;
          }
        }
      }
      this.addProps( props['c'], props['p'], props['s'] );
    }
	},

	//from legacy
	addProps: function ( catProps, prodProps, skuProps, insert ) {
		// For now, i'm using id's, but we may want to use the "path", which is more specific...
		var catId = catProps.CATEGORY_ID;
		var prodId = prodProps.PRODUCT_ID;
		var skuId = skuProps.SKU_ID;
		
		// I'm paranoid - check for id's
		if ( !catId || !prodId || !skuId ) return;
		
		// Insert/update sku object
		var skuObj = this.skuList[skuId] || {};
		this.skuList[skuId] = jQuery.extend(skuObj,skuProps);
		
		// If existing product record, use that and update.
		// Else create new one.
		var prodObj = this.productList[prodId] || { parseOrder: ++this.parseOrderHi };
		
		// If inserting, parse order should be negative.
		if ( insert && prodObj.parseOrder > 0 ) {
			prodObj.parseOrder = --this.parseOrderLo;
		}
		
		prodObj = jQuery.extend(prodObj,prodProps);
		if ( !prodObj.skus )
			prodObj.skus = [];
		if ( !prodObj.skuList )
			prodObj.skuList = {};
		// Make sure each sku is listed only once per product
		if ( !prodObj.skuList[skuId] ) 
			prodObj.skus.push(skuObj);
		prodObj.skuList[skuId] = skuObj;
		this.productList[prodId] = prodObj;
		
		skuObj.product = prodObj;
		
		var catObj = this.categoryList[catId] || {};
		catObj = jQuery.extend(catObj,catProps);
		if ( !catObj.prods ) catObj.prods = [];
		catObj.prods.push(prodObj);		
		this.categoryList[catId] = catObj;
	},
	
	
	// Return an array of product objects, sorted by parseOrder
	getProducts: function() {
	  function sortByParseOrder( a, b ) {
      if ( a.parseOrder > b.parseOrder ) {
        return 1;
      } else if ( a.parseOrder < b.parseOrder ) {
        return -1;
      }
      return 0;
		}
		
		function sortByDisplayOrder( a, b ) {
	    if ( a.DISPLAY_ORDER > b.DISPLAY_ORDER ) {
        return 1;
      }
	    else if ( a.DISPLAY_ORDER < b.DISPLAY_ORDER ) {
        return -1;
      }
      return 0;
		}
		
		var prods = [];
	    
    for ( var prodId in this.productList ) {
        this.productList[prodId].skus.sort(sortByDisplayOrder);
        prods.push( this.productList[prodId] );
    }

    prods.sort(sortByParseOrder);
		
		return prods;
	},
	
	// Return an array of sku objects
	getSkus: function() {
		var skus = [];
		for ( var sku in this.skuList ) {
		    skus.push( this.skuList[sku] );
		}
		return skus;
	},
	
	getCategory: function(catid) {
		var catObj = ( this.categoryList ? this.categoryList[catid] : null );
		return catObj;
	},
	
	getProduct: function(prodid) {
		var prodObj = ( this.productList ? this.productList[prodid] : null );
		return prodObj;
	},
	
	getSku: function(skuid) {
		var skuObj = ( this.skuList ? this.skuList[skuid] : null );
		return skuObj;
	}
	
});

site.endeca.catalog.product = generic.endeca.catalog.product;


/*
The Endeca data arrives as a JSON hash.  Each record in the hash is a complete sku record,
including all the category, product, and sku properties for that sku.

When we request the data, we request a rollup on PRODUCT_ID.  This groups the
data by product.  The "parent" product is called the "Aggregate Record" (in Endeca-land).
Thus, in the hash, the first sku is the "AggrRecord" (which is a sku representative of
the rolled-up product record), and all the other skus (if any) then follow that record.

Because Endeca flattens the entire database into sku-specific records, before sending
the data to Endeca, we pre-pend each property name with a "c_", "p_", or "s_", to indicate
if that property is a Category, Product, or Sku property (respectively). Then, when
we parse out each Endeca record, we can split the record into category, product, and
sku properties.  As we parse the data, we create a category, product, and sku object
for each sku, and then merge that object into the list of cats/prods/skus in our
EndecaCatalog class.

Note that because a product often has multiple skus, we will see the same product id
more than once as we process those skus.  Thus, we want to "update" the product record
in our list with the new sku info, and not just "add" the product record (since that would
potentially create duplicate product records).  A similar scenario may occur if a
product belongs to more than one category.

Here is a very simplified synopsys of the Endeca data JSON format:

	AggrRecords: [
		{	-- new product
			Records: [
				{
					Properties: {
						c_*
						p_*
						s_*
					}
				}
				{
					Properties: {
						c_*
						p_*
						s_*
					}
				}
			]
		}
		{	-- new product
			Records: [
				{
					Properties: {
						c_*
						p_*
						s_*
					}
				}
				{
					Properties: {
						c_*
						p_*
						s_*
					}
				}
			]
		}
	]
*/
	

/*
    Base endeca control class.
    
    This is the base class that will control all instances of endeca. All instances of endeca will have a control
    class that inherits from this base class.

*/

generic.endeca.control = site.endeca.generic.Class.create({
    initialize: function( args ) {            
        this.configuration = args || site.endeca.configuration;
        
        this.queryString = site.endeca.generic.env.query('qs') || "";
        this.searchTerm = site.endeca.generic.env.query('search') || "";
        
        this.hasResults = false;
        this.hasSearched = 0;
        this.wildcardSearch = false;
        
        this.customClasses = {};
        this.results = {};
        this.queries = {};
        this.catalogs = {};
        this.nodes = {};
        
        if ( this.configuration.mustacheMappings ) { this.loadMustacheMappings(); }
        if ( this.configuration.queries ) { this.generateQueries(); }
        if ( this.configuration.results ) { this.generateResults(); }
        if ( this.configuration.nodes ) { this.generateNodes(); }
        
        if ( this.configuration.coremetricsEnabled ) {
            site.endeca.coremetrics.initialize({ enabled: true });
        }

        if ( this.configuration.omnitureEnabled ) {
            if (site.endeca.omniture) {
                site.endeca.omniture.initialize({ enabled: true });
            }
        }
        
        if ( this.hasAnalyticsIntegrated() && !this.isTypeahead() && this.searchTerm !== "" ) {
            if ( site && site.track ) {
                site.track.disableDefaultPageView();
            }
        }
        
    },
    
    loadMustacheMappings: function () {
        if ( this.configuration.mustacheMappings ) {
            site.endeca.generic.template.loadMustacheMappings({ mappings: this.configuration.mustacheMappings });
        }
    },
    
    generateQueries: function() {
        /*
            Take the information provided from the configuration and instantiate all of the necessary queries for this
            class. Queries will be accessible from this.queries[queryName].
            
        */
        
        for ( var query in this.configuration.queries ) {
            this.queries[query] = new site.endeca.query( jQuery.extend(
                { callbackCompleted: site.endeca.helpers.func.bind( this.searchCompleted, this ) }, this.configuration.query,
                this.configuration.queries[query] || {}
            ));
        }
    },
    
    generateResults: function() {
        /*
            Create custom classes for each of the results configuration objects.
        */
        
        for ( var resultsName in this.configuration.results ) {
            // Allow for optional childClass setting in configuration
            this.configuration.results[resultsName].childClass = this.configuration.results[resultsName].childClass || "";
            
            /* 
                Determine which mixins we should be using for this custom class:
                
                1. Use the mixinKey provided in the configuration for this class.
                2. Remove 'site.endeca' from the childClass string and use the remainder as the mixinKey: 
                    childClass = 'site.endeca.results.products', mixinKey = 'results.products'
                3. Remove 'site.endeca' from the baseClass string and add on the resultName as the mixinKey
                    baseClass = 'site.endeca.results', resultsName = 'products', mixinKey = 'results.products'
                4. Remove 'site.endeca' from the baseClass string as the mixinKey
                    baseClass = 'site.endeca.results', mixinKey = 'results'
                
            */
            
            var mixins =    this.configuration.mixins[this.configuration.results[resultsName].mixinKey] || 
                            this.configuration.mixins[this.configuration.results[resultsName].childClass.replace(/site\.endeca\./, '')] ||
                            this.configuration.mixins[this.configuration.results[resultsName].baseClass.replace(/site\.endeca\./, '') + '.' + resultsName] ||
                            this.configuration.mixins[this.configuration.results[resultsName].baseClass.replace(/site\.endeca\./, '')];

            var baseClass = eval(this.configuration.results[resultsName].baseClass);
            
            // Use childClass provided in configuration or
            // Use resultsName to retrieve childClass from baseClass or
            // use an empty object
            var childClass = eval(this.configuration.results[resultsName].childClass) || baseClass[resultsName] || {};
            
            // Create a custom class created from the baseClass, appropriate mixins, and the childClass
            this.customClasses[resultsName] = site.endeca.generic.Class.create( site.endeca.generic.Class.mixin( baseClass, mixins ), childClass );
            
            // Instantiate custom class in this.results[resultsName]
            // Pass in the mixins from configuration for use in result(s) generation
            // Pass in any configuration settings specified in the configuration file for this class
            // Pass in any instanceArgs specified in the configuration file for this class
            this.results[resultsName] = new this.customClasses[resultsName]( jQuery.extend( {}, { mixins: this.configuration.mixins, configuration: this.configuration.results[resultsName].configuration || {} }, this.configuration.results[resultsName].instanceArgs || {} ) );
        }
    },
    
    generateNodes: function() {
        for ( var nodeName in this.configuration.nodes ) {
            this.nodes[nodeName] = this.configuration.nodes[nodeName];
        }
    },
    
    search: function( args ) {
        var args = args || {
            searchTerm: null,
            queryString: null
        };
        
        this.hasSearched++;
        
        this.showLoading();
        this.resetQueries();
        
        // Get searchTerm from queryString here in order to synchronize all queries on the same search term
        var queryString = args.queryString || this.queryString || '';
        var searchTerm = queryString ? site.endeca.helpers.string.toQueryParams( queryString )['Ntt'] : ( args.searchTerm || this.searchTerm || '' );
        
        for ( var query in this.queries ) {
            this.queries[query].searchTerm = searchTerm;
            this.queries[query].queryString = this.queries[query].noQueryString ? '' : queryString;
            this.queries[query].prepare();
            this.queries[query].execute();
            
            this.searchTerm = this.queries[query].searchTerm;
        }
    },
    
    searchCompleted: function( args ) {
        if ( this.queriesCompleted() ) {
            this.resetResults();
            
            for ( var query in this.queries ) {
    		    this.catalogs[query] = new site.endeca.catalog[query]({ jsonResult: this.queries[query].jsonResult });
    		}
    		
            this.meta = new site.endeca.meta({ query: this.queries.product, jsonResult: this.queries.product.jsonResult, searchKey: this.queries.product.searchKey, configuration: { followRedirects: this.configuration.followRedirects, sorting: this.configuration.sorting, contentzones: this.configuration.contentzones } });
            
            if ( this.meta.redirecting ) { 
                // fire redirection event
                if ( this.hasAnalyticsIntegrated() && site && site.elcEvents ) {
                    site.elcEvents.dispatch('track:searchRedirect', this);
                }
                return false; 
            }
            
            this.hideLoading();
           
            // fire search loaded event - delayed to give utag a chance to load
            if ( this.hasAnalyticsIntegrated() && !this.wildcardSearch && site && site.elcEvents ) {
                var that = this;
                if ( this.isTypeahead() ) {
                    site.elcEvents.dispatch('track:searchPredicted', that);
                    site.elcEvents.addListener('track:ready', function() {
                        site.elcEvents.dispatch('track:searchTypeaheadLoaded', that);
                    });
                } else {
                    site.elcEvents.addListener('track:ready', function() {
                        site.elcEvents.dispatch('track:searchPageLoaded', that);
                    });
                }
            }
            
            return true;
        }
        
        return false;
    },
    
    
    
    queriesCompleted: function() {
        for ( var query in this.queries ) {
            if ( !this.queries[query].completed ) { return false; }
        }
        return true;
    },
    
    processCoremetrics: function( args ) {
        var args = args || {
            pageView: true
        };
        // this should be called from your searchCompleted in your instance's control subclass.
        if ( this.configuration.coremetricsEnabled ) { 
            site.endeca.coremetrics.reset();
            site.endeca.coremetrics.pageView = args.pageView;
            site.endeca.coremetrics.productCount = this.meta.searchInfo.totalProductRecords;
            site.endeca.coremetrics.contentCount = this.meta.searchInfo.totalContentRecords;
            site.endeca.coremetrics.searchTerm = this.meta.searchInfo.correctedTerms && this.meta.searchInfo.correctedTerms.length ? this.meta.searchInfo.correctedTerms[0] : this.queries.product.parsedSearchTerm();
            site.endeca.coremetrics.wildcardSearch = this.wildcardSearch;
            site.endeca.coremetrics.numberOfPages = this.meta.pagination ? this.meta.pagination.numberOfPages : 1;
            site.endeca.coremetrics.currentPage = this.meta.pagination ? this.meta.pagination.numberOfCurrentPage : 1;
            if ( this.meta.dimensions.breadcrumbs ) {
                for ( var i = 0; i < this.meta.dimensions.breadcrumbs.length; i++ ) {
                    for ( var j = 0; j < this.meta.dimensions.breadcrumbs[i]["Dimension Values"].length; j++ ) {
                        site.endeca.coremetrics.addRefinement({
                            dimensionName: this.meta.dimensions.breadcrumbs[i]["Dimension Name"],
                            refinement: this.meta.dimensions.breadcrumbs[i]["Dimension Values"][j]["Dim Value Name"]
                        });
                    }
                }
            }
            site.endeca.coremetrics.setPageView(); 
        }
    },

    processOmniture: function() {
        // this should be called from your searchCompleted in your instance's control subclass.
        if ( this.configuration.omnitureEnabled ) {
            site.endeca.omniture.reset();

            // Will use tms_page_data instead of site.endeca because that brings this data under the helm of the Generic
            // Data Dictionary for tagging.
            site.endeca.omniture.productCount = this.meta.searchInfo.totalProductRecords;
            site.endeca.omniture.contentCount = this.meta.searchInfo.totalContentRecords;
            site.endeca.omniture.searchTerm = this.meta.searchInfo.correctedTerms && this.meta.searchInfo.correctedTerms.length ? this.meta.searchInfo.correctedTerms[0] : this.queries.product.parsedSearchTerm();
            site.endeca.omniture.numberOfPages = this.meta.pagination ? this.meta.pagination.numberOfPages : 1;
            site.endeca.omniture.currentPage = this.meta.pagination ? this.meta.pagination.numberOfCurrentPage : 1;
            
            var searchType = this.configuration.searchType || this.queries.product.searchKey;
            if ( searchType ) {
                site.endeca.omniture.searchType = searchType;
            }

            if (searchType == "all") {
               if ( this.meta.dimensions.breadcrumbs ) {
                  var lastBC = this.meta.dimensions.breadcrumbs[ this.meta.dimensions.breadcrumbs.length - 1 ];
                  var lastBCVal = lastBC["Dimension Values"][ lastBC["Dimension Values"].length - 1 ];
                  site.endeca.omniture.refineSearch( lastBCVal["Dim Value Name"] );
               } else {
                  site.endeca.omniture.searchResults();
               }
           }
        }
    },
    
    //somewhat fragile, but can be overridden at the brand level if needed.
    isTypeahead: function() {
        if ( this.configuration.minSearchLength ) {
            return 1;
        } else {
            return 0;
        }
    },
    
    hasAnalyticsIntegrated: function() {
        if ( Drupal && Drupal.settings && Drupal.settings.analytics ) {
            return Drupal.settings.analytics.analytics_integrated;
        } else {
            return 0;
        }
    },
    
    showLoading: function() {
        if ( this.nodes.loading ) {
            this.nodes.loading.show();
        }
    },
    
    hideLoading: function() {
        if ( this.nodes.loading ) {
            this.nodes.loading.hide();
        }
    },
    
    displayResults: function() {
        if ( this.hasResults ) {
            if ( this.results.bestsellers ) { this.results.bestsellers.hide(); }
            if ( this.results.content ) { this.results.content.show(); }
            if ( this.nodes.resultsContainer ) { this.nodes.resultsContainer.show(); }
            if ( this.nodes.noResultsContainer ) { this.nodes.noResultsContainer.hide(); }
	        this.processCoremetrics();
                this.processOmniture();
            this.wildcardSearch = false;
            return true;
        } else {
            if ( this.wildcardSearch ) {
                if ( this.nodes.resultsContainer ) { this.nodes.resultsContainer.hide(); }
                if ( this.nodes.noResultsContainer ) { this.nodes.noResultsContainer.show(); }
                if ( this.results.content ) { 
                    if ( this.configuration.noResultsContentZone ) {                
                        this.results.content.contentzones = this.configuration.noResultsContentZone;
                        this.results.content.resultData = this.meta.supplementalContent;
                        this.results.content.displayResults();
                        this.results.content.show();
                    } else {
                        this.results.content.hide(); 
                    }
                }
                if ( this.results.bestsellers ) {
            	    this.results.bestsellers.displayResults();
            	    this.results.bestsellers.show();
                }
                
	            this.processCoremetrics();
                    this.processOmniture();
                this.wildcardSearch = false;
                return true;
            } else {
                this.wildcardSearch = true;
                this.search({ searchTerm: this.searchTerm + '*' });
                return false;
            }
        }
    },  
         
    
    resetQueries: function() {
        for ( var query in this.queries ) {
            this.queries[query].reset();
        }
    },
    
    resetResults: function() {  
        this.hasResults = false;
        for ( var resultsName in this.results ) {
            this.results[resultsName].reset();
        }
    }
});

site.endeca.control = generic.endeca.control;

/**

Is this a GENERIC file - if values need to be modified, they either need to be passed in from control.js OR this file can be extended at the instance level (see example in sites/clinique/na/js/pc/site/endeca/instances/foundation_finder/option/coremetrics.js)

**/

generic.endeca.coremetrics = {
    enabled: false,
    category_id: "search",
    page_id: "Search Results",
    productCount: 0,
    contentCount: 0,
    searchTerm: null,
    refinementsList: [],
    numberOfPages: 1,
    currentPage: 1,
    pageView: true,
    dimensionNameMap: {
        "Skin Type" : "Typ",
        "Skin Tone" : "Ton"
    },
    wildcardSearch: false,
    
    initialize: function( args ) {
        jQuery.extend( this, args );
    },
    
    addRefinement: function ( args ) {
        var args = args || {};
        if ( args.dimensionName && args.refinement ) {
            var dimensionName;
            if ( this.dimensionNameMap[args.dimensionName] ) {
                dimensionName = this.dimensionNameMap[args.dimensionName];
            } else {
                var dimensionNameWords = args.dimensionName.split(' ');
                dimensionName = dimensionNameWords.shift().substr(0,3);
                for ( var i = 0; i < dimensionNameWords.length; i++ ) {
                    dimensionName += dimensionNameWords[i].charAt(0);
                }
            }
            
            this.refinementsList.push( dimensionName + ':' + args.refinement );
        }
    },
    
    setPageView: function () {
        if ( this.pageView ) {            
            var PAGE_ID = this.page_id + " " + this.currentPage;
            var CATID = this.category_id;
            var KEYWORDS = this.searchTerm;
            var RESULTS = this.contentCount + this.productCount;
            var FILTERLIST = this.refinementsList.join(' > ');
            
            if ( FILTERLIST ){
                PAGE_ID = 'Search Results Filtered ' + this.currentPage;
            }
            
            if ( this.contentCount > 0 && this.productCount == 0 ) {
                KEYWORDS = '*' + KEYWORDS;
            }
            
            if ( typeof cmCreatePageviewTag == 'function' ) {
                cmCreatePageviewTag( PAGE_ID, KEYWORDS, CATID, RESULTS.toString(), FILTERLIST );
            }
            
            if ( this.wildcardSearch ) {
                if ( typeof cmCreateConversionEventTag == 'function' ) {
        	        cmCreateConversionEventTag("RESULTS PAGE", 1, "ENDECA WILDCARD SEARCH", 1);
        	    }
            } else {
                if ( typeof cmCreateConversionEventTag == 'function' ) {
        	        cmCreateConversionEventTag("RESULTS PAGE", 1, "NO ENDECA WILDCARD SEARCH", 1);
        	    }
            }
        }
    },
    
    contentClick: function() {
        if ( typeof cmCreatePageElementTag == 'function' ) {
            cmCreatePageElementTag("CONTENT", "SEARCH DROPDOWN");
        }
    },
    
    productClick: function() {
        if (typeof cmCreatePageElementTag === 'function') {
          cmCreatePageElementTag("PRODUCTS", "SEARCH DROPDOWN");
        }

        if ( this.wildcardSearch ) {
            if ( typeof cmCreateConversionEventTag == 'function' ) {
	            cmCreateConversionEventTag("SEARCH DROPDOWN", 1, "ENDECA WILDCARD SEARCH", 1);
	        }
        } else {
            if ( typeof cmCreateConversionEventTag == 'function' ) {
	            cmCreateConversionEventTag("SEARCH DROPDOWN", 1, "NO ENDECA WILDCARD SEARCH", 1);
	        }
        }
    },
    
    seeAllClick: function() {
        if ( typeof cmCreatePageElementTag == 'function' ) {
            cmCreatePageElementTag("SEE ALL","SEARCH DROPDOWN");
        }
    },
    
    reset: function() {
        this.refinementsList = [];
        this.pageView = true;
    }
};

site.endeca.coremetrics = generic.endeca.coremetrics;

var tms_page_data = tms_page_data || {};

site.endeca.omniture = {
    enabled: false,
    page_id: "Search Results",
    productCount: 0,
    contentCount: 0,
    searchTerm: null,
    searchType: "Regular",
    refinementsList: [],
    numberOfPages: 1,
    currentPage: 1,
    
    initialize: function( args ) {
        jQuery.extend( this, args );
    },
    
    searchResults: function () {
        var PAGE_ID = this.page_id + " " + this.currentPage;
        var KEYWORDS = this.searchTerm;
        var SEARCH_TYPE = this.searchType;
        var RESULTS = this.contentCount + this.productCount;
        
        if ( this.contentCount > 0 && this.productCount == 0 ) {
            KEYWORDS = '*' + KEYWORDS;
        }
        
        omnidata = [KEYWORDS, this.contentCount, this.productCount, PAGE_ID, SEARCH_TYPE];
        if(typeof tms_page_data.tms_page_info != "undefined") {
            tms_page_data.tms_page_info['SEARCH'] = omnidata; 
        } else {
            tms_page_data['SEARCH'] = omnidata;
        }
            jQuery(window).trigger("OMNISEARCH", [omnidata]);
        // console.log("SC PAGE VIEW");
    },
    
    refineSearch: function( refinementName ) {
        omnidata = [refinementName, this.productCount];
        if(typeof tms_page_data.tms_page_info != "undefined") {
            tms_page_data.tms_page_info['FILTERSEARCH'] = omnidata;
        } else {
            tms_page_data['FILTERSEARCH'] = omnidata;
        }
        jQuery(window).trigger("FILTERSEARCH",[omnidata]);
        // console.log("SEARCH FILTER EVENT",omnidata);
    },
    
    contentClick: function() {
        
    },
    
    productClick: function() {
        var PAGE_ID = this.page_id + " " + this.currentPage;
        var KEYWORDS = this.searchTerm;
        var SEARCH_TYPE = this.searchType;
        var RESULTS = this.contentCount + this.productCount;

        if ( this.contentCount > 0 && this.productCount == 0 ) {
            KEYWORDS = '*' + KEYWORDS;
        }

        omnidata = [KEYWORDS, this.contentCount, this.productCount, PAGE_ID, SEARCH_TYPE];
        if(typeof tms_page_data.tms_page_info != "undefined") {
            tms_page_data.tms_page_info['TYPEAHEAD'] = omnidata;
        } else {
            tms_page_data['TYPEAHEAD'] = omnidata;
        }
        jQuery(window).trigger("OMNISEARCH", [omnidata]); 
        //console.log("product click",omnidata);
        $(document).trigger("SEARCHPRODUCTCLICK");
    },
    
    seeAllClick: function() {
        jQuery(window).trigger("SEARCHALLCLK"); 
    },
    
    reset: function() {
        this.refinementsList = [];
    }
};


/*
    Endeca Meta class.
    This provides a convenience wrapper for parsing and normalizing the metadata of Endeca results.
    After parsing, we can access the properties we care about as valid js properties.
    There is no other use for this class (please don't add page/state specific
    code in this module).

*/

generic.endeca.meta = site.endeca.generic.Class.create({
    initialize: function( args ) {
        this.jsonResult = null;
        this.searchKey = "all";
        this.hasRedirects = false;
        this.redirecting = false;
        
        this.pagination = null;
        this.sorting = [];
        this.dimensions = {};
        this.metaInfo = {};
        this.searchInfo = {};
        this.supplementalObjects = [];
        this.supplementalContent = {};
        
        jQuery.extend( this, args );
        
        if ( this.jsonResult ) { this.parseData(); }
    },
    
    parseData: function( args ) {
        this.processMetaInfo();
        this.processSearchInfo();
        this.processSupplemental();
        this.processPagination();
        this.processSorting();
        this.processDimensions();
    },
    
    processMetaInfo: function() {
        this.metaInfo = this.jsonResult.MetaInfo || this.metaInfo;
        
        this.numberOfPages = this.getMetaProp( "Number of Pages" , 1 );
  		this.recordsReturned = this.getMetaProp( "Number of Records Returned", 0 );
  		this.recordsPerPage = this.getMetaProp( "Number of Records per Page", 0 );
  		this.totalMatchRecords = this.getMetaProp( "Total Number of Matching Records", 0 );
  		this.totalAggrRecords = this.getMetaProp( "Total Number of Matching Aggregate Records", 0 );
    },
    
    processSearchInfo: function( args ) {
        var args = args || { totalContentRecords: 0 };
        var searchInfo = this.jsonResult && this.jsonResult["Search Info"] && this.jsonResult["Search Info"][this.searchKey] ? this.jsonResult["Search Info"][this.searchKey] : {};
        this.searchInfo.searchTerm = searchInfo["Search Term"] || "";
        
        if ( this.searchInfo.searchTerm.match(/\*$/) ) {
            this.searchInfo.searchTerm = this.searchInfo.searchTerm.slice(0,-1);
        }
        
        this.searchInfo.totalContentRecords = args.totalContentRecords || 0;
        this.searchInfo.totalProductRecords = this.totalAggrRecords || this.totalMatchRecords;
        
        this.searchInfo.contentResultText = this.searchInfo.totalContentRecords == 1 ? site.endeca.generic.rb('endeca').get('content_result') : site.endeca.generic.rb('endeca').get('content_results');
        this.searchInfo.productResultText = this.searchInfo.totalProductRecords == 1 ? site.endeca.generic.rb('endeca').get('product') : site.endeca.generic.rb('endeca').get('products');
        
        this.searchInfo.totalRecords = parseInt(this.searchInfo.totalContentRecords) + parseInt(this.searchInfo.totalProductRecords);
        this.searchInfo.resultText = this.searchInfo.totalRecords == 1 ? site.endeca.generic.rb('endeca').get('result') : site.endeca.generic.rb('endeca').get('results');
        this.searchInfo.productText = this.searchInfo.totalRecords == 1 ? site.endeca.generic.rb('endeca').get('product') : site.endeca.generic.rb('endeca').get('products');

        this.searchInfo.startingRecord = this.searchInfo.totalProductRecords ? this.getMetaProp( "Starting Record Number", 1 ) : 0;
  		this.searchInfo.endingRecord = this.getMetaProp( "Ending Record Number", 1 );
        
        this.searchInfo.originalRecords = this.searchInfo.totalRecords;
        this.searchInfo.originalResultText = this.searchInfo.resultText;
        this.searchInfo.originalProductText = this.searchInfo.productText;
        
        // If we matched on a computed phrase AFTER the spell correction,
		// then the "Spell Correction" flag is not set properly.
		// Also, the "New Term" will have quotes around it, which further
		// messes up the comparison.  So... to test for spell correction,
		// see if the user's search term is NOT within the corrected term.
		this.searchInfo.correctedTerms = [];
		if ( searchInfo["Spell Correction"] ) {
		    for ( var i = 0; i < searchInfo["Spell Correction"].length; i++ ) {
                if ( searchInfo["Spell Correction"][i]["New Term"].toLowerCase().indexOf( this.searchInfo.searchTerm.toLowerCase() ) < 0 && 
                     searchInfo["Spell Correction"][i]["AutoPhrasing"] == "false" ) {
                    this.searchInfo.correctedTerms.push( searchInfo["Spell Correction"][i]["New Term"] );
                }
            }
		}
        
        if ( this.searchInfo.correctedTerms.length ) { 
            this.searchInfo.originalRecords = this.searchInfo.totalRecords;
            this.searchInfo.originalResultText = this.searchInfo.originalRecords == 1 ? site.endeca.generic.rb('endeca').get('result') : site.endeca.generic.rb('endeca').get('results');
            this.searchInfo.originalProductText = this.searchInfo.originalRecords == 1 ? site.endeca.generic.rb('endeca').get('product') : site.endeca.generic.rb('endeca').get('products');
        }
        
        
  		// Quirk: With computed phrases turned on, if the user enters
  		// an exact phrase that we have in our search config, we'll get
  		// a dym entry of the quoted phrase.  Since this is confusing and unnecessary,
  		// we'll just skip those dym's.
  		
        this.searchInfo.didYouMean = [];
		if ( searchInfo["DYM Information"] ) {
            for ( var i = 0; i < searchInfo["DYM Information"].length; i++ ) {
                if (    searchInfo["DYM Information"][i]["New Term"].toLowerCase().indexOf( this.searchInfo.searchTerm.toLowerCase() ) < 0 && 
                     searchInfo["DYM Information"][i]["AutoPhrasing"] == "false" ) {
                    this.searchInfo.didYouMean.push( {
                        "Selection Link": searchInfo["DYM Information"][i]["Pivot Link"],
                        "Term": searchInfo["DYM Information"][i]["New Term"]
                    });
                }
            }
        }
    },
    
    processPagination: function() {
        if ( this.numberOfPages > 1 ) {
            var viewAllQuery = new site.endeca.query( jQuery.extend( true, {}, site.endeca.configuration.query, { recordsPerPage: 10000 } ) );
            
            this.pagination = {
                numberOfPages: this.numberOfPages,
                numberOfCurrentPage: this.getMetaProp ( "Page Number" , 1 ),
                viewAllLink: this.query.getMergedQueryString( viewAllQuery.toQueryString() ),
                previousPageLink: this.getMetaProp( "Previous Page Link" ),
                nextPageLink: this.getMetaProp( "Next Page Link" ),
      		    directPageLinks: this.getMetaProp( "Direct Page Links", '' )
            };
      	}
      	
      	/** Do we want to merge the select link? If so, we can do that here:
      	if ( this.numberOfPages > 1 ) {
            var query = new site.endeca.query({ recordsPerPage: 10000 });
            
            this.pagination = {
                numberOfPages: this.numberOfPages,
                numberOfCurrentPage: this.getMetaProp ( "Page Number" , 1 ),
                viewAllLink: { "Selection Link": query.toQueryString() },
                previousPageLink: { 'Selection Link': this.getMetaProp( "Previous Page Link" ) },
                nextPageLink: { 'Selection Link': this.getMetaProp( "Next Page Link" ) },
      		    directPageLinks: this.getMetaProp( "Direct Page Links", '' )
            };
            
            for ( var i = 0; i < this.pagination.directPageLinks.length; i++ ) {
                this.pagination.directPageLinks[i] = { "Selection Link": this.pagination.directPageLinks[i], "Content": i+1 };
            }
      	}
      	**/
    },
    
    processSorting: function() {
        var addSort = this.getMetaProp ( "Add Sort Key Links", [] );
        var sortedBy = this.getMetaProp ( "Sorted By", [] );
        
        if ( addSort.length ) {
            this.sorting = this.sorting.concat( addSort );
        }
        
        if ( sortedBy.length ) {
            this.sorting = this.sorting.concat( sortedBy );
        }
    },
    
    processDimensions: function() {        
        var breadcrumbs = [];
        var refinements = [];
        
        if ( this.jsonResult.Breadcrumbs ) {
            for ( var i = 0; i < this.jsonResult.Breadcrumbs.length; i++ ) {
                var bc = this.jsonResult.Breadcrumbs[i];
                if ( bc && bc["Type"] == 'Navigation' ) {
                    for ( var j = 0; j < bc["Dimension Values"].length; j++ ) {
                        bc["Dimension Values"][j]["Removal Link"] = this.query.getMergedQueryString( bc["Dimension Values"][j]["Removal Link"], parseInt( bc["Dimension Values"][j]["Dim Value ID"] ) );
                    }
                    breadcrumbs.push(bc);
                }
            }
        }
        
        if ( this.jsonResult.Refinements ) {
            for ( var i = 0; i < this.jsonResult.Refinements.length; i++ ) {
                var ref = this.jsonResult.Refinements[i];
                if ( ref && ref["Dimensions"] && ref["Dimensions"][0] && ref.Dimensions[0]["Dimension Values"] ) {
                    for ( var j = 0; j < ref.Dimensions[0]["Dimension Values"].length; j++ ) {
                        ref.Dimensions[0]["Dimension Values"][j]["Selection Link"] = this.query.getMergedQueryString( ref.Dimensions[0]["Dimension Values"][j]["Selection Link"] );
                    }
                    refinements.push(ref["Dimensions"][0]);
                }
            }
        }
        
        if ( breadcrumbs.length ) { this.dimensions.breadcrumbs = breadcrumbs; }
        if ( refinements.length ) { this.dimensions.refinements = refinements; }
    },
    
    processSupplemental: function() {
        this.supplementalObjects = this.jsonResult["Supplemental Objects"] || this.supplementalObjects;
        
        for ( var i = 0; i < this.supplementalObjects.length; i++ ) {
            var supplementalObject = this.supplementalObjects[i];
            if ( supplementalObject.Properties ) {
                if ( supplementalObject.Properties["DGraph.KeywordRedirectUrl"] ) {
                    this.hasRedirects = true;
                    if ( this.configuration.followRedirects ) {
                        this.redirecting = true;
                        document.location.href = supplementalObject.Properties["DGraph.KeywordRedirectUrl"];
                    }
		            return false;
				}
				if ( supplementalObject.Properties["DGraph.SeeAlsoMerchId"] ) {
				    var zone = supplementalObject.Properties.Zone;
				    
				    var content = this.supplementalContent[zone] || { records: [] };
				    content.style = supplementalObject.Properties.Style;
				    
                    supplementalObject = this.highlightSearchTermInSupplemental( supplementalObject );
				    
				    if ( !supplementalObject.Properties[ 'suppress_' + ( jQuery.cookie('LOCALE') || 'en_US' ) ] ) {
				        content.records.push( supplementalObject );
				    }
				    
                    this.supplementalContent[zone] = content;
				}
            }
        }
    },
    
    highlightSearchTermInSupplemental: function( supplementalObject ) {
        // Highlight search term in supplemental content -- this is defined on the brand level, see example on BobbiBrown: 
        // drupal-7.9/sites/bobbibrown/modules/custom/bb_endeca/libraries/endeca_search_brand_config/meta.js
        
        return supplementalObject;
    },
    
    // Convenience function to look for prop and return default if not found
	getMetaProp: function( property, def ) {
		var val = ( this.metaInfo[property] || def );
		if ( !isNaN(val) ) {
			val = parseInt(val);
		}
		return val;
	}
    
});

site.endeca.meta = generic.endeca.meta;

/*
    Endeca query class
        
*/

var Drupal = Drupal || {};
Drupal.settings = Drupal.settings || {};
Drupal.settings.endeca = Drupal.settings.endeca || {};

generic.endeca.query = site.endeca.generic.Class.create({
    initialize: function( args ) {
        this.configuration = args || {};
        
        this.sessionId = Math.floor(Math.random()*999999)+1; // no longer used to allow for akamai caching
        
        this.url = '/enrpc/JSONControllerServlet.do';
        this.urlParams = {};
        
        this.queryString = null;
        this.searchTerm = '';
        this.searchKey = 'all';
        this.searchMode = 'matchallpartial';
        
        this.searchDimensions = true;
        this.computePhrasings = true;
        this.didYouMean = true;
        
        this.recordsPerPage = 10;
        this.pageNumber = 1;
        
        this.sortKey = null;
        
        this.rollup = true;
        this.rollupId = 'p_PRODUCT_ID';
        this.rollupDetail = true;
/*

// Moved to site-level configuration.js

        this.defaultRangeFilters = {
            skuShoppable: 's_shoppable|GT+0',
            skuPromotional: 's_promotional|GT+0',
            skuSearchable: 's_searchable|GT+0',
            productTrFlag: 'p_TR_FLAG|LT+1',
            productDisplayable: 'p_displayable|GT+0',
            productShoppable: 'p_shoppable|GT+0'
        };
        this.rangeFilters = ['skuSearchable'];
        this.additionalRangeFilters = [];
                
        this.defaultRecordFilters = {
            products: 'rec_type:product',
            content: 'rec_type:content',
            locale: 'locale:' + site.endeca.generic.cookie('LOCALE'),
            activeSkus: 'NOT(s_INVENTORY_STATUS:5)',
            discontinued: 'NOT(s_discontinued:1)',
            shoppableOrComingSoon: 'OR(s_shoppable:1,s_isComingSoon:1)'
        };
        this.recordFilters = [];
        this.additionalRecordFilters = [];
*/

        // These should be configured in the sitewide configuration.js file.
        
        this.configuredRangeFilters = {} // collection of pre-built range filters 
        this.defaultRangeFilters = []; // which pre-built range filters to use on this query
        this.rangeFilters = []; // pre-built range filters applied via instance configuration
        this.additionalRangeFilters = []; // endeca sytnax range filters applied via instance configuration
        
        this.configuredRecordFilters = {}; // collection of pre-built record filters        
        this.defaultRecordFilters = []; // which pre-built record filters to use on this query
        this.recordFilters = []; // pre-built record filters applied via instance configuration
        this.additionalRecordFilters = []; // endeca sytnax record filters applied via instance configuration
        
        this.recordId = null; // only set this if you want to only retrieve this specific record
        
        this.configureLocale( { 
            'props': [ 'MDEXHost', 'MDEXPort', 'logHost', 'logPort', 'defaultDimensionIds' ],
            'locale': jQuery.cookie('LOCALE') || Drupal.settings.endeca.locale || 'en_US'
        } );
                
        jQuery.extend( this, this.configuration );
        
        this.recordFilters.push('locale'); //always filter by locale
        
        this.completed = 0; //indicates whether the query is new or not
        this.exportUrlParams = [ 'N', 'Ne', 'Nao', 'Ntt', 'Ns', 'D', 'M' ];
        
        this.setupServer();
	},
	
	reset: function() {
	    this.urlParams = {};
	    this.queryString = null;
	    this.jsonResult = null;
	    this.completed = 0;
	},
	
	prepare: function() {
	    this.setupServer();
	    
	    if ( this.recordId ) { this.setupRecordId(); }
        else if ( this.searchTerm ) {   
            this.searchTerm = jQuery.trim( this.parsedSearchTerm() );
            if ( this.computePhrasings ) { this.setupPhraseComputation(); }
            if ( this.didYouMean ) { this.setupDidYouMean(); }
            if ( this.searchDimensions ) { this.setupDimensionSearch(); }
            this.setupSearch();
        }
        
        if ( this.pageNumber ) { this.setupPage(); }
        if ( this.sortKey ) { this.setupSort(); }
        if ( this.rollup ) { this.setupRollUp(); }
        this.setupNavigation();
        this.setupRangeFilters();
        this.setupRecordFilters();
        
        if ( this.queryString ) { 
            this.queryString = decodeURIComponent(this.queryString);
            this.processQueryString();
        } else {
            this.setupDimensionIds();
        }
	},
	
	execute: function() {
		var url = this.url + '?' + this.toQueryString();
		jQuery.ajax({
            url: url,
	  		type: 'get',
	  		context: this,
	  		complete: this.onComplete
	  	});
	},
	
	onComplete: function(t) {
	    this.completed = 1;
		this.rawResult = t.responseText;

    if (this.rawResult === undefined) {
      return false;
    }
		this.jsonResult = JSON.parse( this.rawResult );
		this.callbackCompleted();
	},
	
    toQueryString: function( args ) {
	    var args = args || {};
	    var queryString = args.urlParams ? jQuery.param(args.urlParams) : jQuery.param(this.urlParams);
	    queryString = queryString.replace(/%2B/gi, '+'); //Endeca is picky about pluses being unencoded
	    return queryString;
	},
	
	processQueryString: function() {
	    this.urlParams = jQuery.extend( true, this.urlParams, site.endeca.helpers.string.toQueryParams( this.queryString ) );
        this.searchTerm = this.urlParams['Ntt'] || '';
        this.setupDimensionIds();
        this.setupRangeFilters();
        this.setupRecordFilters();
	},
	
	/** 
	    Return a new query string reflecting the merging of the current instance's query string and the passed in query string
        Used when preparing a new query for the url with the existing query instance
	 **/
	getMergedQueryString: function( queryString, dimIdsToRemove ) {
	    var newUrlParams = site.endeca.helpers.string.toQueryParams( queryString );
	    
        // If there is no record offset in the new query, add one.
	    if ( !newUrlParams['Nao'] ) { newUrlParams['Nao'] = 0; } 
	    
	    var mergedUrlParams = jQuery.extend(true, {}, this.urlParams, newUrlParams );
	        
	    // Merge Dimension Ids
	    var Ne = this._getDimensionIds({ urlParams: newUrlParams, oldDimensionIds: this.urlParams['Ne'] });
	    Ne = site.endeca.helpers.array.remove( Ne, this.defaultDimensionIds );
	    // For nested dimensions, make sure to remove the selected dimension (this mainly applies to breadcrumbs)
	    Ne = dimIdsToRemove ? site.endeca.helpers.array.remove( Ne, dimIdsToRemove ) : Ne;
	    mergedUrlParams['Ne'] = Ne.join('+');
	    
	    return this.toQueryString({ urlParams: site.endeca.helpers.obj.slice( mergedUrlParams, this.exportUrlParams ) });
	},
	
	setupServer: function() {
	    this.urlParams['M'] = 'host:' + this.MDEXHost + '|port:' + this.MDEXPort + '|recs_per_page:' + this.recordsPerPage;
	    //this.urlParams['L'] = 'SESSION_ID:' + this.sessionId + '|host:' + this.logHost + '|port:' + this.logPort;
	    this.urlParams['L'] = 'host:' + this.logHost + '|port:' + this.logPort;
	},
	
	setupRecordId: function() {
	    this.urlParams['R'] = this.recordId
	},
	
	setupNavigation: function() {
	    this.urlParams['N'] = this.defaultNavigation ? this.defaultNavigation.join('+') : '';
	},
	
	setupDimensionIds: function() {
	    var NeString = this._getDimensionIds({ urlParams: this.urlParams });
	    if ( NeString.length ) { this.urlParams['Ne'] = NeString.join('+') }
	},
	
	setupSearch: function( args ) {
	    var args = args || {};
	    
	    this.urlParams['Ntt'] = ( args.searchTerm || this.searchTerm ).toLowerCase();
      if (typeof Drupal.settings.endeca.searchKey != 'undefined' && Drupal.settings.endeca.searchKey != '') {
        this.searchKey = Drupal.settings.endeca.searchKey;
      }
	    this.urlParams['Ntk'] = this.searchKey;
	    this.urlParams['Ntx'] = this.searchMode.match(/mode\+/) ? this.searchMode : ('mode+' + this.searchMode)
	},
	
	setupDimensionSearch: function( args ) {
	    var args = args || {};
	    
	    this.urlParams['D'] = ( args.searchTerm || this.searchTerm ).toLowerCase();
	    this.urlParams['Dx'] = this.searchMode.match(/mode\+/) ? this.searchMode : ('mode+' + this.searchMode)
	    
	    /* Currently unused. Allows searching of specific dimensions: Di=DIMID+DIMID+DIMID
	    this.queryString += 'Di=';
	    */
	},
	
	setupPhraseComputation: function() {
	    this.urlParams['Ntpc'] = 1
	    this.urlParams['Ntpr'] = 1
	},	
	
	setupDidYouMean: function() {
        this.urlParams['Nty'] = 1  
	},
	
	setupSort: function() {
	    this.urlParams['Ns'] = this.sortKey
	},
	
	setupRollUp: function() {
	    this.urlParams['Nu'] = this.rollupId
	    
	    // type of rollup.  1 = summary only, 2 = all records
	    this.urlParams['Np'] = this.rollupDetail ? 2 : 1
	},
	
	setupPage: function() {
	    // This is actually a record offset, not a page number
	    this.urlParams['Nao'] = (this.pageNumber - 1) * this.recordsPerPage
	},
	
	setupRangeFilters: function() {
	    var filters = this._getFilters(this.defaultRangeFilters, this.rangeFilters, this.additionalRangeFilters, this.configuredRangeFilters);
	    	    
	    if (filters.length) {
	        this.urlParams['Nf'] = filters.join('|')
	    }	    
	},
	
	setupRecordFilters: function() {
        var filters = this._getFilters(this.defaultRecordFilters, this.recordFilters, this.additionalRecordFilters, this.configuredRecordFilters);
	    
	    if (filters.length) {
	        this.urlParams['Nr'] = 'AND(' + filters.join(',') + ')'
	    }
	},
	
	_getFilters: function(defaultFilters, filters, additionalFilters, configuredFilters) {
	    var filterArray = [];
	    
	    jQuery.each( filters, function( index, filter ) {
	         filterArray.push( configuredFilters[filter] );
	    });
	    
	    jQuery.each( defaultFilters, function( index, filter ) {
	         filterArray.push( configuredFilters[filter] );
	    });
	    
	    jQuery.each( additionalFilters, function( index, filter ) {
	        filterArray.push(filter);
	    });
	    
	    return filterArray;
	},
	
	_getDimensionIds: function( args ) {	    
	    var Ne = [];
	    
	    if ( args.oldDimensionIds ) { Ne = Ne.concat( args.oldDimensionIds.split('+') ) }
	    if ( args.addDefaultDimensionIds ) { Ne = Ne.concat( (args.oldDimensionIds).split('+') ) }
	    Ne = Ne.concat( args.urlParams && args.urlParams['Ne'] ? (args.urlParams['Ne']).split('+') : [] );
	    Ne = Ne.concat( this.defaultDimensionIds );
	    Ne = site.endeca.helpers.array.toInt( Ne );
	    Ne = site.endeca.helpers.array.unique( Ne );
	    
	    return Ne;
	},
	
	parsedSearchTerm: function( args ) {
	    var args = args || {};
	    var searchTerm = args.searchTerm || this.searchTerm;
	    
	    var parsedSearchTerm;
	    try {
	        parsedSearchTerm = decodeURIComponent( searchTerm )
	    } catch (e) {
	        parsedSearchTerm = searchTerm;
	    }
	    parsedSearchTerm = parsedSearchTerm.replace(/\+/g, " ");
	    return parsedSearchTerm.match(/[<>]/) == null ? parsedSearchTerm : '';
	},
	
	configureLocale: function( args ) {
	    var args = args || { 'props': [], 'locale': '' };
	    
	    for ( var i = 0; i < args.props.length; i++ ) {
	        if (    typeof this.configuration[ args.props[i] ] == "object" && 
	                typeof this.configuration[ args.props[i] ][ args.locale ] != "undefined" ) {
	            this.configuration[ args.props[i] ] = this.configuration[ args.props[i] ][ args.locale ];
	        }
	    }
	}
   
});


site.endeca.query = generic.endeca.query;

/*
    Endeca result class
    This class represents ONE result ( dom element ) on the page. Each of these results represents one item returned from endeca (product result, content result, refinement link, etc)
    
    Required Arguments:
        resultData: data describing the result, must be a JS obj
        templatePath: path of the template to populate with the resultData
        parentNode: node for the template to be inserted into
        
    this.node will represent the node that has been inserted into the page after the template is rendered.
    

*/

generic.endeca.result = site.endeca.generic.Class.create({
    initialize: function( args ) {
        this.parentNode = null;
        this.node = null;
        this.resultData = null;
        this.templatePath = null;
        
        jQuery.extend( this, args || {} );
    },
    
    displayResult: function( args ) {
        var args = args || {};
        var parentNode = args.parentNode || this.parentNode;
        var that = this;
        site.endeca.generic.template.get({ 
            path: that.templatePath,
            object: args.resultData || that.resultData,
            rb: rb ? rb.endeca : null,
            callback: function(html) {
                html = jQuery.trim(html);
                if ( html ) {
                    that.node = jQuery(html);
                    if ( parentNode && that.node ) {
                        parentNode.append( that.node );
                    }
                    that.displayResultCallback(args);
                }
            }
        });
    },
    
    displayResultCallback: function( args ) { /* Define this in your subclass */ },
    
    reset: function() {
        if ( this.node && this.parentNode && this.node != this.parentNode ) {
            this.node.remove();
        } else {
            this.parentNode.empty();
        }
    },  
    
    hide: function() {
        this.parentNode.hide();
    },
    
    show: function() {
        this.parentNode.show();
    }
});

site.endeca.result = generic.endeca.result;

/*
    Endeca results class
    This class represents a container of result classes (refinements, products, etc).
    
    Required Arguments:
        resultData: array describing the results
        templatePath: path of the template to be used for each result created
        parentNode: node for each result node to be inserted into
    Optional Arguments:
        containerTemplatePath: path to a container template. If provided, this.node will be set to the inserted element. 
        
    
    Optional CSS Selectors:
        results-header: this.headerNode will be set to a child of this.parentNode with class results-header
        results: this.contentNode will be set to a child of this.parentNode with class results. If there is no element with .results, this.node will be set as the contentNode.
        
        
    this.node will represent either of the following:
        1. If you provided a containerTemplatePath, this.node will be the inserted container
        2. If containerTemplatePath has not been provided, this.node will be set to this.parentNode
    

*/

generic.endeca.results = site.endeca.generic.Class.create({
    initialize: function( args ) {
        this.parentNode = null; // Node in which this results container will be inserted
        this.node = null; // Container node for results data - same as this.parentNode if there is no template in this class
        this.headerNode = null; // Header node for results data header, eg "Product Results", Refinement Headers
        this.contentNode = null; // Container node for individual result classes
        
        this.resultData = [];
        this.resultNodes = [];
        
        this.resultClass = null;
        
        this.configuration = {};
        this.mixins = {};
        
        jQuery.extend(this, args);
    },
    
    setupNodes: function( args ) {
        var args = args || {};
        var parentNode = args.parentNode || this.parentNode;
        var containerTemplatePath = args.containerTemplatePath || this.containerTemplatePath;
        this.node = parentNode || args.node;
        if ( this.node ) {
            if ( containerTemplatePath ) { this.loadContainer( { parentNode: parentNode, containerTemplatePath: containerTemplatePath } ); }
            this.headerNode = this.node.find('.results-header:first').length ? this.node.find('.results-header:first') : null;
            this.contentNode = this.node.find('.results:first').length ? this.node.find('.results:first') : this.node;
        }
    },
    
    /*
        create result classes for each element in the resultData array. 
    */
    displayResults: function( args ) {
        var args = args || {};
        args.childClass = args.childClass || this.childClass;
        
        this.setupNodes( args );
        
        var resultData = args.resultData || this.resultData;
        var that = this;
        jQuery.each( resultData, function(index, result) {
            that.createResult( jQuery.extend( args, {
                result: result,
                index: index
            }));
        });
    },
    
    loadContainer: function( args ) {
        var args = args || {};
        var templatePath = args.containerTemplatePath || this.containerTemplatePath;
        var that = this;
        site.endeca.generic.template.get({
            path: templatePath,
            object: args.resultData || that.resultData,
            rb: rb ? rb.endeca : null,
            callback: function(html) {
                html = jQuery.trim(html);
                if ( html ) {
                    that.node = jQuery(html);
                    if ( args.parentNode && that.node ) {
                        args.parentNode.append( that.node );
                    }
                }
            }
        });
    },
    
    createResult: function( args ) {
        var args = args || {};
        
        this.setResultClass( args );
        var config = args.configuration || this.configuration;
        var limit = config?.limit || null;

    if (limit > 0 && (this.resultNodes?.length ?? 0) >= limit) {
            // Stop adding results if the limit was reached.
            // The sku matches create more results then allowed by the limit, breaking layout
            return;
        }
        var result = new this.resultClass({
            parent: this,
            templatePath: args.templatePath || this.templatePath,
            resultData: args.result,
            parentNode: args.contentNode || this.contentNode,
            configuration: config,
            mixins: args.mixins || this.mixins
        });
        this.resultNodes.push( result ); 
    },
    
    /*
        Determine which class to use in displayResults.
        This will usually be specified in the subclass inheriting from this class. 
    */
    setResultClass: function ( args ) {
        var args = args || {};
        var baseClass = args.baseClass || this.baseClass || site.endeca.result;
        
        if ( !this.resultClass ) {
            args.childClass = args.childClass || this.childClass || "";
            var mixins = args.mixins || this.mixins[ this.resultMixinKey ] || this.mixins['result.' + args.childClass] || this.mixins['result'] || {};
            this.resultClass = args.childClass && baseClass[args.childClass] ? site.endeca.generic.Class.create( site.endeca.generic.Class.mixin( baseClass, mixins ), baseClass[args.childClass] ) : site.endeca.generic.Class.mixin( baseClass, mixins );
        }
    },
    
    displayResultNodes: function () {
        for ( var i = 0; i < this.resultNodes.length; i++ ) {
            this.resultNodes[i].displayResult();
        }
        
        if ( this.resultNodes.length ) {
            if ( this.resultNodes[0].node ) { this.resultNodes[0].node.addClass('first'); }
            if ( this.resultNodes[this.resultNodes.length-1].node ) { this.resultNodes[this.resultNodes.length-1].node.addClass('last'); }
        }
    },
    
    hide: function() {
        this.parentNode.hide();
    },
    
    show: function() {
        this.parentNode.show();
    },
        
    reset: function() {
        if ( this.containerTemplatePath ) { 
            if ( this.node ) { this.node.remove(); }
        } else { 
            if ( this.contentNode ) { this.contentNode.empty(); }
            else if ( this.node ) { this.node.empty(); }
        }
        
        this.resultData = [];
        this.resultNodes = [];
    }
});

site.endeca.results = generic.endeca.results;

/*
    Endeca resultsgroup class
    This class represents a group of results classes (all refinments, all pagination blocks, all content zones, etc).
    This class inherits from generic.endeca.results, overwriting the createResult function in order to create new results classes as opposed to new result classes.
    
    Additionally, when instantiating this class, you can pass in an obj called resultsArgs, the contents of which will be passed directly to any results class that is instantiated in createResult. 
*/

generic.endeca.resultsgroup = site.endeca.generic.Class.create( site.endeca.results, {
        
    initialize: function( args ) {
        this.baseClass = site.endeca.results;
        this._super( args );
    },
    
    displayResults: function( args ) {
        var args = args || {};
        var that = this;
        
        if ( that.parentNode && that.parentNode.length > 1 ) {
            that.parentNode.each( function() {
                args.parentNode = jQuery(this);
                that._super( args );
            });  
        } else {
            that._super( args );
        }
    },

    createResult: function( args ) {
        var args = args || {};
        
        args.childClass = this.resultsChildClass || args.childClass;
        args.mixins = args.mixins || this.mixins[ this.resultsMixinKey ] || this.mixins['results.' + args.childClass] || this.mixins['results'];        
        this.setResultClass( args );
        
        var result = new this.resultClass( jQuery.extend( { 
            parent: this,
            resultData: args.result,
            parentNode: this.contentNode || this.node || this.parentNode,
            summaryResultData: this.summaryResultData,
            mixins: this.mixins,
            configuration: args.configuration || this.configuration
        }, args.resultsArgs || this.resultsArgs || {} ) ); 
        this.resultNodes.push( result );
    },
    
    reset: function() {
        for ( var i = 0; i < this.resultNodes.length; i++ ) {
            this.resultNodes[i].reset();
        }
        this._super();
    }

}); 

site.endeca.resultsgroup = generic.endeca.resultsgroup;

generic.endeca.mixins.accordion = { 
    initialize: function( args ) {
        this._super(args);
        
        this.accordionHeaderNode = [];
        this.accordionContentNode = [];
        this.accordionCloseNode = [];
    },
       
    displayResults: function( args ) {
        this._super(args);
        this.setupAccordion();
    },
    
    setupAccordion: function() {
        this.accordionHeaderNode = this.node.find('.accordion-header:first');
        this.accordionContentNode = this.node.find('.accordion-content:first');
        this.accordionCloseNode = this.node.find('.accordion-close:first');
        
        if ( this.accordionHeaderNode.length && this.accordionContentNode.length ) {
            var that = this;
            this.accordionHeaderNode.off( 'click' );
            this.accordionHeaderNode.on( 'click', { that: that }, that.onClick );
            
            if ( this.accordionCloseNode.length ) { 
                this.accordionCloseNode.off( 'click' );
                this.accordionCloseNode.on( 'click', { that: that }, that.closeAccordion );
            }
        }
    },
    
    onClick: function( event ) {
        event.preventDefault();
        
        var that = event.data.that;
        that.toggleAccordion();
        
        return false;
    },
    
    accordionIsOpen: function() {
        return ! this.accordionHeaderNode.hasClass('collapsed');
    },
    
    accordionIsClosed: function() {
        return this.accordionHeaderNode.hasClass('collapsed');
    },
    
    toggleAccordion: function() {
        if ( ! this.accordionHeaderNode.hasClass('collapsed') ) { this.closeAccordion(); }
        else { this.openAccordion(); }
    },
    
    openAccordion: function( event ) {
        var that = event && event.data && event.data.that ? event.data.that : this;
        if ( this.accordionHeaderNode.length && this.accordionContentNode.length && this.accordionIsClosed() ) {
            this.accordionHeaderNode.removeClass('collapsed');
            this.accordionContentNode.addClass('opened');
            this.accordionContentNode.show();
            
            this.accordionContentNode.trigger('accordion:open', that);
        }
    },
    
    closeAccordion: function( event ) {
        var that = event && event.data && event.data.that ? event.data.that : this;
        if ( that.accordionHeaderNode.length && that.accordionContentNode.length && that.accordionIsOpen() ) {
            that.accordionHeaderNode.addClass('collapsed');
            that.accordionContentNode.removeClass('opened');
            that.accordionContentNode.hide();
            
            that.accordionContentNode.trigger('accordion:closed', that);
        }
    }
    
};

site.endeca.mixins.accordion = generic.endeca.mixins.accordion;

generic.endeca.mixins.links = {        
    displayResultCallback: function( args ) {
        var args = args || { };
        args.resultData = args.resultData || this.resultData;
        var node = args.node || args.parentNode || this.node || this.parentNode;
        node = node.find('.link-mixin').length ? node.find('.link-mixin') : 
               node.find('a').length ? node.find('a') : node;
               
        var link = args["Selection Link"] || args.resultData["Selection Link"] || args.resultData["Removal Link"] || node.attr( 'rel' );
        
        if ( link && node ) {
            var that = this;
            node.on( 'click', { that: that, link: link }, that.onClick );
            node.on( 'simulate:click', { that: that, link: link }, that.onClick );
            
            if ( node.href ) { 
                var params = site.endeca.generic.env.parsedQuery();
                params['qs'] = encodeURIComponent(link);
        
                var url = document.location.pathname + "?" + jQuery.param(params);
                node.href = url;
            }
        }
    }
};

site.endeca.mixins.links = generic.endeca.mixins.links;


generic.endeca.mixins.links.address = jQuery.extend( {
    onClick: function( event ) {
        var that = event.data.that;
        jQuery.address.value( "?" + event.data.link );
        if (    that.configuration.scrollTo &&
                that.configuration.scrollTo.length ) {
            jQuery(window).scrollTop( that.configuration.scrollTo.position().top );
        } else if ( !( that.noScroll || that.configuration.noScroll ) ) {
            scroll(0,0)
        }
        event.preventDefault();
        return false;
    }
}, site.endeca.mixins.links );

site.endeca.mixins.links.address = generic.endeca.mixins.links.address;

generic.endeca.mixins.summary = { 
    
    initialize: function( args ) {
        this._super(args);
        
        this.summaryResultData = { totalRecords: this.resultData.length };
        this.summaryResultData.resultText = this.summaryResultData.totalRecords == 1 ? site.endeca.generic.rb('endeca').get('result') : site.endeca.generic.rb('endeca').get('results');
    },
    
    setupNodes: function( args ) {
        var args = args || {};    
        this._super(args);
        if ( this.node ) {
            this.summaryNode = this.node.find('.results-summary').length ? this.node.find('.results-summary') : null;
        }
    },
    
    displayResults: function( args ) {
        this._super(args);
        this.setupSummary();
    },
    
    setupSummary: function() {
        if ( this.summaryNode && this.configuration.summaryTemplatePath && this.summaryResultData ) {
            this.summary = new site.endeca.result({
                parentNode: this.summaryNode,
                templatePath: this.configuration.summaryTemplatePath,
                resultData: this.summaryResultData
            });
            this.summary.displayResult();
        }
    },
    
    reset: function( args ) {
        this._super(args);
        if ( this.summaryNode ) { this.summaryNode.empty() }
    }
};

site.endeca.mixins.summary = generic.endeca.mixins.summary;

var site = site || {};
var generic = generic || {};

generic.endeca.mixins.selectbox = {
  setupNodes: function(args) {
    this._super(args);
    var that = this;

    var selectBox;

    if (this.node.is('select')) {
      selectBox = this.node;
    } else if (this.node.find('select').length) {
      selectBox = this.node.find('select');
    } else if (this.parentNode.find('select').length) {
      selectBox = this.parentNode.find('select');
    }

    if (selectBox) {
      selectBox.on('change', that, that.onChange);
    }
  },

  onChange: function(event) {
    var that = event.data;
    var selectedOption = this.options[this.selectedIndex];
    jQuery(selectedOption).trigger('simulate:click');
    event.preventDefault();
    return false;
  }
};

site.endeca.mixins.selectbox = generic.endeca.mixins.selectbox;

generic.endeca.result.contentDrupal = {
    displayResult: function( args ) {
        var args = args || {};
        
        var resultData = args.resultData || this.resultData;
        var parentNode = args.parentNode || this.parentNode;

        if ( Drupal?.settings?.endeca?.no_results_redirect && resultData?.drupal_redirect_path && resultData?.drupal_redirect_path !== '' ) {
            window.location.href = resultData.drupal_redirect_path;
        } else if ( resultData.drupal_node_id ) {
            jQuery.ajax({
                url: '/' + Drupal.settings.pathPrefix + 'elc_api/endeca_content_result/' + resultData.drupal_node_id,
                context: this,
                complete: function( args ) {
                    var html = args.responseText;
                    html = jQuery.trim(html);
                    if ( html ) {
                        this.node = jQuery(html);
                        if ( parentNode && this.node ) {
                            parentNode.append( this.node );
                        }
                        
                        this.displayResultCallback(args);
                    }
                }
            });
        }
    }
};


site.endeca.result.contentDrupal = generic.endeca.result.contentDrupal;

generic.endeca.result.product = {
    initialize: function( args ) {
        this.shadeResults = null;
        this._super( args );
    },
    
    displayResult: function( args ) {
        this.setupReviewData();
        this.setupBrandData();
        this._super( args );
    },
    
    displayResultCallback: function( args ) {
        this.setupQuickShop();
        this.setupAddToBag( this.resultData.skus[0] );
        if ( this.resultData.shaded ) { this.setupShades(); }
        if ( this.resultData.sized ) { this.setupSizeSelect(); }
        if ( typeof this.displayResultCallbackBrand == "function" ) { this.displayResultCallbackBrand() }
    },
    
    setupReviewData: function() {
        this.resultData.ratingDisplay = this.resultData.TOTAL_REVIEW_COUNT ? 'block' : 'none';
        this.resultData.ratingReviewWord = this.resultData.TOTAL_REVIEW_COUNT && this.resultData.TOTAL_REVIEW_COUNT > 1 ? site.endeca.generic.rb("language").get('reviews') : site.endeca.generic.rb("language").get('review');
        this.resultData.ratingRounded = this.resultData.TOTAL_REVIEW_COUNT ? Math.round(this.resultData.AVERAGE_RATING*10)/10 : 0;
    },
    
    setupBrandData: function() {
        this.resultData.formattedPriceRange = this.formatPriceRange();
        this.resultData.formattedCartridgePrice = typeof this.resultData.GIFTSET_COMPONENTS != 'undefined' ? this.resultData.skus[0].formattedCartridgePrice : '';
        this.resultData.price2 = this.resultData.skus[0].formattedPrice2;
        this.resultData.shadedClass = this.resultData.shaded ? 'shaded' : 'nonshaded';
        this.resultData.sizedClass = this.resultData.sized ? 'sized' : 'notsized';
        this.resultData.isComingSoon = eval( jQuery.map( this.resultData.skus, function( sku ) { return sku.isComingSoon }).join('+') ) > 0 ? "coming_soon" : "";
        if ( this.resultData.DESCRIPTION ) {
            this.resultData.descriptionBlurb = this.resultData.DESCRIPTION.substring( 0, ( this.configuration.descriptionBlurb || 120 ) );
            this.resultData.descriptionRest = this.resultData.DESCRIPTION.substring( ( this.configuration.descriptionBlurb || 120 ) );
            this.resultData.descriptionFull = this.resultData.descriptionBlurb + this.resultData.descriptionRest;
        }
        this.resultData.skinTypeText = typeof productPage != 'undefined' && typeof productPage.getAllSkinTypes == 'function' ? productPage.getAllSkinTypes( this.resultData ) : '';
        if( typeof this.resultData.ATTRIBUTE_BENEFIT != 'undefined' ){
            this.resultData.attrBenefit = this.resultData.ATTRIBUTE_BENEFIT.toString().replace(/,/g,", ");
        }
    },
    
    formatPriceRange: function() {
		var minPrice = this.resultData.skus[0];
		var maxPrice = this.resultData.skus[0];
		for(var i = 0; i < this.resultData.skus.length; i++){
			var currSku = this.resultData.skus[i];
			minPrice = (currSku.PRICE < minPrice.PRICE) ? currSku : minPrice;
			maxPrice = (currSku.PRICE > maxPrice.PRICE) ? currSku : maxPrice;
		}
		
		return ( minPrice !== maxPrice ) ? minPrice.formattedPrice + ' - ' + maxPrice.formattedPrice : this.resultData.skus[0].formattedPrice;
    },
    
    setupQuickShop: function() {
        var quickshopLink = this.node.find('a.quickshop-link');
        
        if ( quickshopLink && typeof brx != 'undefined' ) {
            var that = this;
            quickshopLink.bind("click", function (e) {
                e.preventDefault();
                
                if(jQuery.isFunction(productPage.launchQuickshop)){
                    var prodID = jQuery(this).attr('id').replace('quickview-link-','');
                    productPage.launchQuickshop(prodID);
                }else{
                    var view = brx.productView.quickshop({
                        productData: that.resultData
                    });   
                }
            });
            quickshopLink.bind("mouseover", function (e) {
            	jQuery(this).find('.quickshop-btn').addClass('qs-active');
            	jQuery(this).closest('.result').addClass('qs');
            });
            quickshopLink.bind("mouseout", function (e) {
            	jQuery(this).find('.quickshop-btn').removeClass('qs-active');
            	jQuery(this).closest('.result').removeClass('qs');
            });
        }
    },
    
    setupAddToBag: function( sku ) {
        var skuBaseId = typeof sku==="number" ? sku : sku.SKU_BASE_ID;
        var addButtonNode = this.node.find("a.btn-add-to-bag");
        var progressNode = this.node.find("span.add-progress");
        
        addButtonNode.unbind();
        addButtonNode.attr("data-skubaseid", skuBaseId );
        addButtonNode.bind("click", function(e) {
            e.preventDefault();
            
            if ( progressNode.length ) {
                addButtonNode.hide();
                progressNode.show();

                $(document).one("addToCart.success addToCart.failure", function () {
                    progressNode.hide();
                    addButtonNode.show();
                });
            }
            
            site.addToCart({
                skuBaseId: $(this).attr("data-skubaseid")
            });
            
        });
    },
    
    setupNote: function() {
        /*if (this.resultData.MISC_FLAG) {
            var flagImgNode = this.node.select(".prod_details .prod_title .note")[0];
            if ( flagImgNode ) {
                var flagImg = el.productView.flagImages.get(this.resultData);
                var img = new Element("img", { src: flagImg.mppimg, alt: flagImg.alt });
                flagImgNode.update(img);
            }
        }*/
    },
    
    setupGiftSetComponents: function() {
        /*var giftsetNode = this.node.down('.giftset');
        if ( giftsetNode ) { giftsetNode.show(); }*/
    },
    
    setupShades: function() {
        var shadesNode = this.node.find('.shades');
        var selectedShadesNode = this.node.find('.selected-shade-name');
        var priceNode = this.node.find('.shade-price');
        
        if ( shadesNode.length ) {
            var skus;
            
            if ( typeof this.configuration.maxmimumShades != 'undefined' && this.resultData.skus.length > this.configuration.maxmimumShades ) {
                skus = this.resultData.skus.slice( 0, this.configuration.maxmimumShades );
            } else {
                skus = this.resultData.skus.slice( 0 );
            }
            
            shadesNode.addClass( 'shades_' + this.resultData.skus.length );
            
            for ( var i = 0; i < skus.length; i++ ) {
                skus[i].PRODUCT_ID = this.resultData.PRODUCT_ID;
                skus[i].url = this.resultData.url;
            }
            
            this.shadeResults = new site.endeca.results({ 
                resultData: skus,
                parentNode: shadesNode,
                childClass: 'shade',
                configuration: this.configuration,
                mixins: this.mixins
            });
            this.shadeResults.displayResults();
            this.shadeResults.show();
            
            var that = this;
            
            this.node.bind( 'select.sku', function( event, sku ) {
                that.setupAddToBag( sku.resultData.SKU_BASE_ID );
                if ( selectedShadesNode.length ) {
                    selectedShadesNode.text( sku.resultData.SHADENAME );
                }
                if ( priceNode.length ) {
                    priceNode.text( sku.resultData.formattedPrice );
                }
            });
            
            this.shadeResults.resultNodes[0].selectShade();
            
            // Commenting this out for now as it causes the search page to automatically go to SPP when the result/shade node is a link
            //this.shadeResults.resultNodes[0].node.click();
        }
    },
    
    setupSizeSelect: function() {
        var sizeSelectNode = this.node.find('.size-select');
        var priceNode = this.node.find('.size-price');
        
        if ( sizeSelectNode.length ) {
          
            this.sizeResults = new site.endeca.results({ 
                resultData: this.resultData.skus,
                parentNode: sizeSelectNode,
                childClass: 'size',
                configuration: this.configuration,
                mixins: this.mixins
            });
            this.sizeResults.displayResults();
            this.sizeResults.show();
            
            var that = this;
            
            this.node.bind( 'select.sku', function( event, sku ) {
                that.setupAddToBag( sku.resultData.SKU_BASE_ID );
                if ( priceNode.length ) {
                    priceNode.text( sku.resultData.formattedPrice );
                }
            });
            
        }
        
    }
};


site.endeca.result.product = generic.endeca.result.product;

generic.endeca.result.shade = {
    initialize: function( args ) {
        this._super( args );
        this.templatePath = this.configuration.shadeTemplatePath || '/templates/endeca/products/shade.tmpl';
        this.displayResult();
    },
    
    displayResultCallback: function( args ) {
        this.drawSwatch();
        if ( ! this.configuration.suppressShadeEvents ) {
            this.initListeners();
        }
    },
    
    // Clinique style drawSwatch - this can be customized at the SITE level
    drawSwatch: function( args ) {
        if (!this.resultData.HEX_VALUE_STRING || this.resultData.HEX_VALUE_STRING.length < 1) {
            return;
        }
        
        var swatchContainerNode = this.node.find('.search-swatch-container');
        //var swatchWidth = swatchContainerNode.css('width');
        //swatchWidth = parseInt( swatchWidth.replace('px', '') );
                
        var hexVals = this.resultData.HEX_VALUE_STRING.split(',');
        //var swatchShadeWidth = Math.ceil(swatchWidth/hexVals.length);
        
        for (var i=0; i<hexVals.length; i++) {
            var d = jQuery("<div/>");
            d.css({ 
                //width: swatchShadeWidth + "px",
                'background-color': hexVals[i] 
            });
            if ( i == 0 ) { d.addClass('first'); }
            if ( i == hexVals.length-1 ) { d.addClass('last') }
            if ( hexVals.length == 1 ) { d.addClass('single') }
            
            swatchContainerNode.append(d);
        }
        
        swatchContainerNode.css('width', 'auto');  
    },
    
    initListeners: function( args ) {
        var that = this;
        this.node.on( 'click', that, function( event ) {
            var that = event.data;
            that.selectShade();
        });
    },
    
    selectShade: function( args ) {
        this.node.siblings('.shade').removeClass('active');
        this.node.addClass('active');
        this.node.trigger( 'select.sku', this );
    }
};

site.endeca.result.shade = generic.endeca.result.shade;

site.endeca.result.size = {
    initialize: function( args ) {
        this._super( args );
        this.templatePath = '/templates/endeca/products/size.tmpl';
        this.displayResult();
    },
    
    displayResultCallback: function( args ) {
        this.initListeners();
    },
    
    initListeners: function( args ) {
        var that = this;
        this.node.on( 'click', that, function( event ) {
            var that = event.data;
            this.node.trigger( 'select.sku', that );
        });
    }
    
};

generic.endeca.result.summary = {
    displayResult: function( args ) {
        var args = args || {};

        var templates = jQuery.extend( {
            results: '/templates/endeca/summary/results.tmpl',
            noResults: '/templates/endeca/summary/no-results.tmpl',
            noTerm: '/templates/endeca/summary/no-term.tmpl',
            autoCorrect: '/templates/endeca/summary/auto-correct.tmpl',
            didYouMean: '/templates/endeca/summary/did-you-mean.tmpl'
        }, ( this.configuration.templatePaths || {} ) );
        
        if ( this.resultData.searchTerm == "" ) { this.templatePath = templates['noTerm'] }
        else if ( this.hasResults ) { 
            this.templatePath = templates['results'];
            
            this.resultData.productAnchorLinkDisplay = this.resultData.totalProductRecords > 0 ? 'inline' : 'none';
            this.resultData.contentAnchorLinkDisplay = this.resultData.totalContentRecords > 0 ? 'inline' : 'none';
            this.resultData.productResultText += this.resultData.totalContentRecords > 0 ? ',' : '';
            
        } else { this.templatePath = templates['noResults'] }
        this._super(args);
        
        var searchTerms = this.node.find('.searchTerms');
        if ( this.resultData.breadcrumbs && searchTerms.length ) {
            var breadcrumbs = [];
            for ( var i = 0; i < this.resultData.breadcrumbs.length; i++ ) {
                for ( var j = 0; j < this.resultData.breadcrumbs[i]['Dimension Values'].length; j++ ) {
                    breadcrumbs.push( ', "' + this.resultData.breadcrumbs[i]['Dimension Values'][j]['Dim Value Name'] + '"' );
                }
            }
            searchTerms.append( breadcrumbs.join("") );
        }
        
        var acElement = this.node.find('#auto-correct');
        if ( this.resultData.correctedTerms && this.resultData.correctedTerms.length && acElement.length ) { 
            this.templatePath = templates['autoCorrect'];
            args.resultData = this.resultData;
            args.resultData.correctedTerm = this.resultData.correctedTerms.join(',');
            args.parentNode = acElement;
            this._super(args)
        }
        
        var dymElement = this.node.find('#did-you-mean');
        if ( this.resultData.didYouMean &&this.resultData.didYouMean.length && dymElement.length ) { 
            this.templatePath = templates['didYouMean'];
            args.resultData = this.resultData.didYouMean[0]; // Only handle the first did you mean term
            args.parentNode = dymElement;
            this._super(args)
        }
    },
    
    reset: function() {
        this.parentNode.empty();
    }
};

site.endeca.result.summary = generic.endeca.result.summary;

generic.endeca.results.breadcrumbs = {
    initialize: function(args) {
        this.childClass = 'breadcrumb';
        this._super(args);
        
        if ( (typeof this.resultData.length == "undefined" && this.resultData ) || this.resultData.length ) {
            this.displayResults();
        }
    },
    
    displayResults: function() {
        this.resultData['Dimension Name RB Key'] = this.resultData['Dimension Name'].replace(/\W+/gi, "_").toLowerCase();        
        this.resultData['Dimension Name RB'] = site.endeca.generic.rb('endeca').get( 'dimension_' + this.resultData['Dimension Name RB Key'] );
        this.resultData['Dimension Description RB'] = site.endeca.generic.rb('endeca').get( 'dimension_' + this.resultData['Dimension Name RB Key'] + '.description' );
        
        var that = this;
        var rd = jQuery.map(this.resultData["Dimension Values"], function( dimVal ){ 
            return jQuery.extend( dimVal, { 
                "Dimension Name": that.resultData["Dimension Name"],
                "Dimension Name RB Key": that.resultData["Dimension Name RB Key"],
                "Dimension Name RB": that.resultData["Dimension Name RB"],
                "Dimension Description RB": that.resultData["Dimension Description RB"]
            });
        });
        
        this._super({
            resultData: this.resultData["Dimension Values"]
        });
        
        this.displayResultNodes();
    },
    
    createResult: function( args ) {
        args.templatePath = this.childTemplatePath || this.templatePath || "/templates/endeca/breadcrumbs/link.tmpl";
        
        if ( this.configuration.breadcrumbTemplates && this.configuration.breadcrumbTemplates[ this.resultData["Dimension Name RB Key"] ] ) {
            args.templatePath = this.configuration.breadcrumbTemplates[ this.resultData["Dimension Name RB Key"] ];
        }
        
        args.result['Dim Value Name RB Key'] = args.result['Dim Value Name'].replace(/\W+/gi, "_").toLowerCase();
        args.result['Dim Value Name RB'] = site.endeca.generic.rb('endeca').get( 'refinement_' + args.result['Dim Value Name RB Key'] );
        
        this._super( args );
    }
}; 

site.endeca.results.breadcrumbs = generic.endeca.results.breadcrumbs;

generic.endeca.results.bestsellers = {
        
    initialize: function( args ) {
        this.childClass = 'product';
        this._super( args );
    },
    
    displayResults: function() {
        var that = this;
        
        this.query = new site.endeca.query( jQuery.extend({ 
                callbackCompleted: function() {
                    var productCatalog = new site.endeca.catalog.product({ jsonResult: that.query.jsonResult });
                    that.resultData = productCatalog.getProducts();
                    
                    that._super();
                    that.displayResultNodes();
                    if ( typeof that.configuration.queryCallback == 'function' ) {
                        that.configuration.queryCallback();
                    }
                }
            }, 
            site.endeca.configuration.query,
            this.configuration.queryArgs || {}
        ));
        
        this.query.prepare();
        this.query.execute();
    },
    
    createResult: function( args ) {
        args.result.context = 'bestseller-product';
        args.templatePath = this.childTemplatePath || this.templatePath || "/templates/endeca/products/bestseller-result.tmpl";
        this._super(args);
    }
};

site.endeca.results.bestsellers = generic.endeca.results.bestsellers;

generic.endeca.results.contentzone = site.endeca.generic.Class.create( site.endeca.results, {
    
    displayResults: function( args ) {
        var args = args || {};
        
        // setting this here doesn't allow for different styles per result -- so you can only have one containerTemplate per zone and it will always be for the first result, this is clunky
        this.zoneName = this.resultData[0].Properties.Zone;
        this.styleName = this.resultData[0].Properties.Style;
        
        this.styleConfig = this.configuration.styles && this.configuration.styles[ this.styleName ] ? 
            this.configuration.styles[ this.styleName ] :
            undefined;
        
        args.containerTemplatePath = this.configuration.containerTemplatePath;
        
        if ( typeof this.configuration.containerTemplatePath == "object" ) {
            args.containerTemplatePath = this.configuration.containerTemplatePath[ this.styleName ]
        } else if ( this.styleConfig && this.styleConfig.containerTemplatePath ) {
            args.containerTemplatePath = this.styleConfig.containerTemplatePath;
        }
        
        this._super(args);
    },
    
    createResult: function( args ) {
        var args = args || {};
        
        // checking this here allows for resultsets with different styles/templates in each result. 
        var styleName = ( args.result.Properties && args.result.Properties.Style ) ? args.result.Properties.Style : this.styleName;
        
        args.templatePath = this.configuration.templatePath;
        
        if ( typeof this.configuration.templatePath == "object" ) {
            args.templatePath = this.configuration.templatePath[ styleName ]
        } else if ( this.styleConfig && this.styleConfig.templatePath ) {
            args.templatePath = this.styleConfig.templatePath;
        }
            
        this._super(args);
    }
    
});

site.endeca.results.contentzone = generic.endeca.results.contentzone;

generic.endeca.results.contentzone.content = {
        
    initialize: function( args ) {
        //this.childClass = 'content';
        this._super(args);
        
        if ( this.resultData.length ) {
            this.displayResults();
        }
    },
    
    displayResults: function( args ) {
        this._super(args);
        
        this.displayResultNodes();
        
        /*this.resultNodes.each( function( result ) { 
            if ( result.resultData.content_link == "undefined" ) {
                result.node.down('.content-link').hide();
            }
        });*/
    },
    
    createResult: function( args ) {
        args.result = args.result.Properties;
        if ( args.result.Style.match(/drupal/i) ) { args.childClass = 'contentDrupal'; }
        this._super(args);
    }
    
};

site.endeca.results.contentzone.content = generic.endeca.results.contentzone.content;

generic.endeca.results.contentzone.products = {

    initialize: function( args ) {
        this.childClass = 'product';
        this._super(args);
        this.query = null;
        this.totalProductResults = 0;
        var searchTerms = [];

        for ( var i = 0; i < this.resultData.length; i++ ) {
            for ( var j = 0; j < this.resultData[i]['Records'].length; j++ ) {
                searchTerms.push(this.resultData[i]['Records'][j]["Record Spec"]);
                this.totalProductResults++;
            }
        }

        this.query = new site.endeca.query( jQuery.extend({
                callbackCompleted: site.endeca.helpers.func.bind( this.searchCompleted, this ),
                searchMode: 'matchany',
                searchTerm: searchTerms.join(' '),
                searchKey: 'rec_id'
            },
            site.endeca.configuration.query,
            this.configuration.queryArgs || {}
        ));

        this.query.prepare();
        this.query.execute();
    },

    searchCompleted: function() {
        var productCatalog = new site.endeca.catalog.product({ jsonResult: this.query.jsonResult });

        this.displayResults({ resultData: productCatalog.getProducts() });
        this.displayResultNodes();
    },

    createResult: function( args ) {
        args.result.context = 'featured-product';
        args.result.callout = site.endeca.generic.rb('endeca').get('callout.featured-product');
        this._super(args);
    }

};

site.endeca.results.contentzone.products = generic.endeca.results.contentzone.products;

generic.endeca.results.pagination = {

    initialize: function( args ) {
        this._super(args);
        this.displayResults();
    },
    
    displayResults: function() {
        if ( this.resultData ) {
            this.setupNodes();
            
            if ( this.resultData.viewAllLink && this.configuration.viewAllLink ) {
                this.createResult({
                    templatePath: this.configuration.viewAllPageTemplatePath || "/templates/endeca/pagination/viewall.tmpl",
                    result: { "Selection Link": this.resultData.viewAllLink }
                });
            }            
        
            if ( this.resultData.previousPageLink && this.configuration.previousPageLink ) {
                this.createResult({
                    templatePath: this.configuration.previousPageTemplatePath || "/templates/endeca/pagination/previous.tmpl",
                    result: { "Selection Link": this.resultData.previousPageLink }
                });
      		}
      		
      		var oldContentNode = this.contentNode;
      		if ( this.configuration.containerTemplatePath ) {
      		    this.containerTemplatePath = this.configuration.containerTemplatePath
      		    this.setupNodes();
          	}
      		
      		if ( this.resultData.directPageLinks ) {
      		    for ( var i = 0; i < this.resultData.directPageLinks.length; i++ ) {
          		    this.createResult({
        	            templatePath: this.resultData.numberOfCurrentPage == i+1 ?  this.configuration.currentTemplatePath || "/templates/endeca/pagination/current.tmpl" :  this.configuration.linkTemplatePath || "/templates/endeca/pagination/link.tmpl",
                        result: { "Selection Link": this.resultData.directPageLinks[i], "Content": i+1 }
                    });
          		}
      		}
      		
      		this.contentNode = oldContentNode;
      		        
            if ( this.resultData.nextPageLink && this.configuration.nextPageLink ) {
                this.createResult({
                    templatePath: this.configuration.nextPageTemplatePath || "/templates/endeca/pagination/next.tmpl",
                    result: { "Selection Link": this.resultData.nextPageLink }
                });
      		}
      		
      		this.displayResultNodes();
        }
    },
    
    setupSummary: function () {
        return;
        if ( this.paginationSummaryNode ) {
            if ( this.summaryResultData && this.summaryResultData.totalProductRecords > 1 ) {
                var templatePath = "endeca.templates.pagination.summary.shown";            
                if ( this.resultData && this.resultData.numberOfCurrentPage == 1 ) {
                    templatePath = "endeca.templates.pagination.summary.topShown";
                }
                
                this.createResult({
                    templatePath: templatePath,
                    result: this.summaryResultData,
                    contentNode: this.paginationSummaryNode
                });
            }
            
            if ( this.resultData && this.resultData.nextPageLink ) {
                this.createResult({
                    templatePath: "endeca.templates.pagination.summary.next",
                    result: { "Selection Link": this.resultData.nextPageLink },
                    contentNode: this.paginationSummaryNode
                });
            }
            
            if ( this.summaryResultData ) {
                var templatePathView = null;
                var queryArgs = null;
                if ( this.summaryResultData.startingRecord == 1 && this.summaryResultData.endingRecord > 10 ) {
                    templatePathView = "endeca.templates.pagination.summary.viewLess";
                    queryArgs = {};
                } else if ( this.summaryResultData.totalProductRecords > 10 ) {
                    templatePathView = "endeca.templates.pagination.summary.viewAll";
                    queryArgs = { recordsPerPage: 10000 }
                }
                
                if ( templatePathView ) {
                    var query = new site.endeca.base.query(queryArgs);
                    this.createResult({
                        templatePath: templatePathView,
                        result: { "Selection Link": query.toQueryString() },
                        contentNode: this.paginationSummaryNode
                    }); 
                }
            }
            
        }
    }
};

site.endeca.results.pagination = generic.endeca.results.pagination;


generic.endeca.results.products = {
        
    initialize: function( args ) {
        this.childClass = 'product';
        this._super( args );
    },
    
    displayResults: function( args ) {
        var args = args || {};
        this._super( args );
        this.displayResultNodes();
    },
    
    createResult: function( args ) {
        args.templatePath = this.childTemplatePath || "/templates/endeca/products/result.tmpl";
        args.result.context = 'product';
        this._super( args );
    }    
};

site.endeca.results.products = generic.endeca.results.products;

generic.endeca.results.refinements = {
    
    initialize: function( args ) {
        this.containerTemplatePath = '/templates/endeca/refinements/container.tmpl';
        this.childClass = 'refinement';
        this.moreRefinement = null;
        this.refinementIDs = {};
        this._super( args );
        
        this.resultData['Dimension Name RB Key'] = this.resultData['Dimension Name'].replace(/\W+/gi, "_").toLowerCase();        
        this.resultData['Dimension Name RB'] = site.endeca.generic.rb('endeca').get( 'dimension_' + this.resultData['Dimension Name RB Key'] );
        this.resultData['Dimension Description RB'] = site.endeca.generic.rb('endeca').get( 'dimension_' + this.resultData['Dimension Name RB Key'] + '_description' );

        if ( this.configuration.refinementContainerTemplates && this.configuration.refinementContainerTemplates[ this.resultData["Dimension Name RB Key"] ] ) {
            this.containerTemplatePath = this.configuration.refinementContainerTemplates[ this.resultData["Dimension Name RB Key"] ];
        }
        
        this.displayResults({
            resultData: this.resultData["Dimension Values"]
        });
        
        this.displayResultNodes();
    },
    
    createResult: function( args ) {        
        args.templatePath = this.childTemplatePath || this.templatePath || "/templates/endeca/refinements/link.tmpl";
        
        if ( this.configuration.refinementTemplates && this.configuration.refinementTemplates[ this.resultData["Dimension Name RB Key"] ] ) {
            args.templatePath = this.configuration.refinementTemplates[ this.resultData["Dimension Name RB Key"] ];
        }
        
        if ( this.configuration.resultMixinKeys && this.configuration.resultMixinKeys[ this.resultData["Dimension Name RB Key"] ] ) {
            args.mixins = this.mixins[ 
                this.configuration.resultMixinKeys[ this.resultData["Dimension Name RB Key"] ]
            ];
        }
                
        if ( this.resultData["Dim Value Properties"] && this.resultData["Dim Value Properties"]["DGraph.More"] && args.result["Dim Value Name"] == "More..." ) {
            args.templatePath = "/templates/endeca/refinements/show-all.tmpl";
            this.moreRefinement = args.result["Dim Value ID"];
        } else {
            this.refinementIDs[ args.result["Dim Value ID"] ] = 1;
            args.result['Dim Value Name RB Key'] = args.result['Dim Value Name'].replace(/\W+/gi, "_").toLowerCase();        
            args.result['Dim Value Name RB'] = site.endeca.generic.rb('endeca').get( 'refinement_' + args.result['Dim Value Name RB Key'] );
            args.result['Dim Value Description RB'] = site.endeca.generic.rb('endeca').get( 'refinement_description_' + args.result['Dim Value Name RB Key'] );
        }
        
        this._super( args );
    },
    
    reset: function( args ) {
        this.refinementIDs = {};
        this._super( args );
    }

}; 

site.endeca.results.refinements = generic.endeca.results.refinements;

generic.endeca.results.sorting = {

    initialize: function( args ) {
        this._super(args);
        this.displayResults();
    },
    
    displayResults: function( args ) {
        this._super( args );
        this.displayResultNodes();
    },
    
    createResult: function( args ) {
        var args = args || {};
        
        if ( args.result['Sort Order'] ) {
            args.templatePath = this.configuration.currentTemplatePath || "/templates/endeca/sort/current.tmpl";
        } else {
            args.templatePath = this.configuration.linkTemplatePath || "/templates/endeca/sort/link.tmpl";
        }
        
        args.result['Sort Key RB'] = args.result['Sort Key'] ? site.endeca.generic.rb('endeca').get( 'sorting_' + args.result['Sort Key'].toLowerCase() ) : "";
        
        this._super(args);
    }
};


site.endeca.results.sorting = generic.endeca.results.sorting;

generic.endeca.resultsgroup.breadcrumbs = {
    displayResults: function( args ) {
        var args = args || {};
        args.childClass = 'breadcrumbs';
        this._super(args);
        
        var that = this;        
        jQuery('.clear-all').each( function( index, el ) {
            var $this = jQuery(this);
            // Mixed in via endeca.mixins.links...
            if ( that.displayResultCallback ) { 
                that.displayResultCallback({
                    "Selection Link" : ' ',
                    "node": $this
                }); 
                $this.show();
            } else {
                $this.hide();
            }
        });
    }
};

site.endeca.resultsgroup.breadcrumbs = generic.endeca.resultsgroup.breadcrumbs;


generic.endeca.resultsgroup.contentzones = {
        
    initialize: function( args ) {
        this.resultData = {};
        this.zones = {};
        this.crawlData = [];
        this.hasSoloResults = false;
        this.totalResults = 0;
        this.contentzones = {};
        this._super(args);
    },  
    
    setCrawlData: function( args ) {
        var args = args || { };
        if ( args.crawlData && args.crawlData.length ) { this.resultData.crawlData = { style: 'content', records: args.crawlData }; }
    },
    
    setupNodes: function( args ) {
        var args = args || {};
        this.node = this.parentNode || args.node;
    },
    
    displayResults: function( args ) {
        var args = args || {};
        
        args.baseClass = site.endeca.results.contentzone;
        
        for ( var zone in this.contentzones ) {
            // reset the result class for each zone as they may be following a different inheritance path
            this.resultClass = null; 
            
            var zoneArgs = args;
            var content = this.resultData[zone];
            
            if ( content && content.records && content.records.length ) {
                /*if ( this.contentzones[zone].limit && this.resultData[zone].records.length > this.contentzones[zone].limit ) {
                    zoneArgs.resultData = [ this.resultData[zone].records.slice( 0, this.contentzones[zone].limit ) ];
                } else {
                    zoneArgs.resultData = [ this.resultData[zone].records ];
                }*/
                
                zoneArgs.resultData = [ this.resultData[zone].records ];
                
                zoneArgs.childClass = this.contentzones[zone].childClass || (
                    site.endeca.results.contentzone[this.resultData[zone].style] ? 
                        this.resultData[zone].style : 
                        this.resultData[zone].style.match(/product/i) ? 'products' : 'content' 
                );
                zoneArgs.node = this.contentzones[zone].node;
                if ( this.contentzones[zone].solo ) { this.hasSoloResults = true; }
                if ( this.contentzones[zone].counted ) { this.totalResults += zoneArgs.resultData[0].length; }
                zoneArgs.configuration = this.contentzones[zone];
                zoneArgs.mixins = this.mixins['results.contentzone'];
                this._super(zoneArgs);
                this.contentzones[zone].resultNode = this.resultNodes[ this.resultNodes.length-1 ];
                zoneArgs.node.show();
            } else {
                this.contentzones[zone].node.hide();
            }
            
        }
    },
    
    /*
    //Initial attempt at using styles to build out custom result heirarchy - in order to do this, the contentzone resultsgroup/results/result structure will have to be modified. Currently resultsgroup represents ALL zones and sets up results classes that all have the same subclass, which doesn't allow you to mix product results with content results in one zone. In this setup, each result is a standard "result" class. 
    
    // To improve this, the following heirarchy should be adopted:
    //      resultsgroup.contentzones should still represent all zones. 
    //      results.contentzones should represent each style in a zone.
    //      result.contentzone.style should represent each individual result, with any custom, style-specific logic.
     
    createResult: function( args ) {
        try {
            args.childClass = args.configuration.styles[args.style].childClass;
        } catch(e) {
            args.childClass = 'content';
        }
        this._super( args );
    },
    */
    
    hide: function() {
        for ( var i = 0; i < this.resultNodes.length; i++ ) {
            this.resultNodes[i].hide();
        }
    },
    
    show: function() {
        for ( var i = 0; i < this.resultNodes.length; i++ ) {
            this.resultNodes[i].show();
        }
    },
    
    reset: function() {
        for ( var i = 0; i < this.resultNodes.length; i++ ) {
            this.resultNodes[i].reset();
        }
        this.resultNodes = [];
        this.totalResults = 0;
    }
    
};

site.endeca.resultsgroup.contentzones = generic.endeca.resultsgroup.contentzones;

generic.endeca.resultsgroup.pagination = {
    
    displayResults: function( args ) {
        var args = args || {};
        
        args.childClass = 'pagination';
        
        var that = this;
        jQuery('.pagination').each( function() {
            that.parentNode = jQuery(this);
            args.resultData = [that.resultData];
            that._super(args);
        });
    }
};

site.endeca.resultsgroup.pagination = generic.endeca.resultsgroup.pagination;


generic.endeca.resultsgroup.refinements = {
    
    initialize: function( args ) {
        this.moreRefinements = [];
        this._super( args );
    },
    
    displayResults: function( args ) {
        var args = args || {};
        
        args.childClass = 'refinements';
        this._super( args );
        
        var that = this;        
        jQuery('.expand-all').each( function( el, index ) {
            if ( that.moreRefinements.length ) {
                // Mixed in via endeca.mixins.links...
                if ( that.displayResultCallback ) { 
                    that.displayResultCallback({
                        "Selection Link" : 'Ne=' + that.moreRefinements().join('+'),
                        "node": el
                    }); 
                }
                el.show();
            } else {
                el.hide();
            }
        });
    },
    
    moreRefinements: function() {
        if ( this.moreRefinements.length ) {
            return this.moreRefinements;
        } else {
            for ( var i = 0; i < this.resultNodes.length; i++ ) {
                if ( this.resultNodes[i].moreRefinement ) { this.moreRefinements.push( this.resultNodes[i].moreRefinement ); }
            }
        }
    },
    
    reset: function( args ) {
        this.moreRefinements = [];
        this._super( args );
    }
    
};

site.endeca.resultsgroup.refinements = generic.endeca.resultsgroup.refinements;

